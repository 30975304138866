import { initializeApp } from 'firebase/app'
import { getMessaging, getToken } from 'firebase/messaging'

const firebaseConfig = {
  apiKey: 'AIzaSyAlgVRze1vam1D_tEcnTIQTdhdbnAjVMvk',
  authDomain: 'brave-monitor-330208.firebaseapp.com',
  projectId: 'brave-monitor-330208',
  storageBucket: 'brave-monitor-330208.appspot.com',
  messagingSenderId: '931092057691',
  appId: '1:931092057691:web:cf86a20e883757628ff97e'
}

const app = initializeApp(firebaseConfig)

export { app, getMessaging, getToken }
