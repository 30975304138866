import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import VueApexCharts from 'vue-apexcharts'
import VCalendar from 'v-calendar'

Vue.use(VCalendar, {
  componentPrefix: 'vc'
})

Vue.use(VueApexCharts)
Vue.component('apexchart', VueApexCharts)
Vue.use(Vuetify)

export default new Vuetify({
  customVariables: ['~/assets/scss/fonts.scss'],
  theme: {
    dark: false,
    themes: {
      light: {
        primary: '#162447',
        error: '#E33F5A',
        secondary: '#2D90A3',
        white: '#D1DFE4',
        grey: '#7C8591'
      },
      dark: {
        primary: '#162447',
        error: '#E33F5A',
        secondary: '#2D90A3',
        white: '#D1DFE4',
        grey: '#7C8591'
      }
    }
  }
})
