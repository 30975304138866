import Vue from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios'

axios.defaults.baseURL = process.env.NODE_ENV !== 'production' ? 'http://localhost:3030' : window.location.host === 'pilot.susiair.com' ? 'https://api.susiair.com' : 'https://api-staging.susiair.com'
axios.defaults.headers.post['Content-Type'] = 'application/json'
axios.defaults.headers.common.role = 'true'
axios.interceptors.request.use(function (config) {
  config.headers.Authorization = 'Bearer ' + (localStorage.getItem('t') || '')
  return config
}, function (error) {
  return Promise.reject(error)
})

Vue.use(VueAxios, axios)
