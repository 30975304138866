import request from '../../_api/request'

export default {
  async ARTICLE_GET (context, query) {
    return request.get('article/find' + (query || '')).then((res) => {
      if (res.status) {
        context.commit('SET_ARTICLE', { data: res.data.data.data, total: parseInt(res.data.data.total) })
      } else {
        context.commit('SET_ARTICLE', { data: [], total: 0 })
      }
    }).catch(e => null)
  },
  async ARTICLE_ADD (context, params) {
    return request.post('article', params).then((res) => res).catch(e => null)
  },
  async ARTICLE_UPDATE (context, params) {
    return request.put('article/' + params.id, params).then((res) => res).catch(e => null)
  }
}
