import request from '../../_api/request'

export default {
  async EMPLOYEE_GET (context, query) {
    return request.get('user/find' + (query || '')).then((res) => {
      if (res.status) {
        context.commit('SET_EMPLOYEE', { data: res.data.data.data, total: parseInt(res.data.data.total) })
      } else {
        context.commit('SET_EMPLOYEE', { data: [], total: 0 })
      }
    }).catch(e => null)
  },
  async EMPLOYEE_ADD (context, params) {
    return request.post('user', params).then((res) => res).catch(e => null)
  },
  async EMPLOYEE_UPDATE (context, params) {
    return request.put('user/' + params.id, params).then((res) => res).catch(e => null)
  }
}
