import Vue from 'vue'
import Vuex from 'vuex'
import request from './_api/request'
import modules from './lib'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    admin: null,
    news: {
      total: 0,
      all: [],
      data: []
    },
    me: null,
    me_flighttime: 0,
    airport: {
      total: 0,
      all: [],
      data: []
    },
    base: {
      total: 0,
      all: [],
      data: []
    },
    logbook: {
      aircrafts: [],
      pilots: [],
      airports: [],
      bases: [],
      area: []
    },
    logbookHistory: {
      total: 0,
      data: []
    },
    schedules: {
      total: 0,
      data: []
    },
    pilot_schedule: {
      total: 0,
      data: []
    },
    exportSchedule: null,
    pilot_status_form_leave: [
      { color: 'blue', name: 'Days Off', sn: 'OFF', status: 0 },
      { color: 'red', name: 'Sick', sn: 'SCK', status: 3 },
      { color: 'grey darken-2', name: 'Unpaid Leave', sn: 'UL', status: 10 },
      { color: 'indigo', name: 'Compassionate', sn: 'CO', status: 11 }
    ],
    pilot_status: [
      { color: 'transparent', name: 'Day Off', sn: '', status: 0 },
      { color: 'green', name: 'On Duty', sn: 'DUTY', status: 1 },
      { color: 'grey darken-2', name: 'Requested Leave', sn: 'RL', status: 2 },
      { color: 'red', name: 'Sick', sn: 'SCK', status: 3 },
      { color: 'orange', name: 'Travel Day', sn: 'TR', status: 4 },
      { color: 'yellow', name: 'Training', sn: 'TX', status: 5 },
      { color: 'grey', name: 'Administration', sn: 'ADM', status: 6 },
      { color: 'brown', name: 'Ferry', sn: 'FER', status: 7 },
      { color: 'purple', name: 'Medical', sn: 'MED', status: 8 },
      { color: 'aqua', name: 'Recurrent', sn: 'REC', status: 9 },
      { color: 'black', name: 'Unpaid Leave', sn: 'UL', status: 10 }
    ],
    flight_type: [
      { name: 'Regular Flight', value: 'regular', color: '#157402' },
      { name: 'Perintis Flight', value: 'perintis', color: '#b66202' },
      { name: 'Perintis Flight (Cargo)', value: 'perintis_cargo', color: '#8b4b01' },
      { name: 'Charter Flight', value: 'charter', color: '#1c39be' },
      { name: 'Charter Flight (Cargo)', value: 'charter_cargo', color: '#0825a7' },
      { name: 'Ferry Flight', value: 'ferry', color: '#05b9b0' },
      { name: 'Corporate Flight', value: 'corporate', color: '#576fbe' },
      { name: 'Medevac Flight', value: 'medevac', color: '#550101' },
      { name: 'Positioning', value: 'positioning', color: '#8605b9' },
      { name: 'Training', value: 'training', color: '#d303a6' },
      { name: 'Simulator', value: 'simulator', color: '#696969' },
      { name: 'Cargo Flight', value: 'cargo', color: '#978800' }
    ],
    pilot_status_old: [
      { color: 'gray', name: 'Day Off', sn: 'X' },
      { color: 'green', name: 'Standby', sn: 'O' },
      { color: 'orange', name: 'Travel', sn: 'Tv' },
      { color: 'red', name: 'Train', sn: 'Tn' },
      { color: 'red', name: 'Sick', sn: 'S' },
      { color: 'red', name: 'Kitas', sn: 'K' },
      { color: 'red', name: 'Medical', sn: 'M' },
      { color: 'blue', name: 'Recurrent', sn: 'R' },
      { color: 'blue', name: 'PPC', sn: 'P' },
      { color: 'red', name: 'DGCA', sn: 'D' },
      { color: 'red', name: 'Check Ride', sn: 'CK' },
      { color: 'red', name: 'Line Training', sn: 'LT' },
      { color: 'grey', name: 'Unpaid Leave', sn: 'UL' },
      { color: 'grey', name: 'Away', sn: 'A' }
    ],
    pilot_aircraft: [],
    pilot_airport: []
  },
  mutations: {
    ADMIN_SET (state, v) {
      state.admin = v
    },
    SET_NEWS (state, v) {
      if (v.add) {
        state.news.data = state.news.data.concat(v.data || [])
      } else {
        state.news.data = v.data || []
      }
      state.news.total = parseInt(v.total) || 0
    },
    AIRPORT_SET (state, v) {
      state.airport.total = parseInt(v.total) || 0
      if (v.all) {
        state.airport.all = v.all || []
      } else {
        state.airport.data = v.data || []
      }
    },
    BASE_SET (state, v) {
      state.base.total = parseInt(v.total) || 0
      if (v.all) {
        state.base.all = v.all || []
      } else {
        state.base.data = v.data || []
      }
    },
    EXPORT_SCHEDULE (state, v) {
      state.exportSchedule = v
    },
    ME_SET (state, v) {
      state.me = v || null
    },
    ME_FLIGHTTIME_SET (state, v) {
      state.me_flighttime = v || 0
    },
    LOGBOOK_AIRCRAFTS (state, v) {
      state.logbook.aircrafts = v || []
    },
    LOGBOOK_PILOTS (state, v) {
      state.logbook.pilots = v || []
    },
    LOGBOOK_AIRPORTS (state, v) {
      state.logbook.airports = v || []
    },
    LOGBOOK_BASES (state, v) {
      state.logbook.bases = v || []
    },
    LOGBOOK_AREA (state, v) {
      state.logbook.area = v || []
    },
    LOGBOOK_HISTORY (state, v) {
      if (v.add) {
        state.logbookHistory.data = state.logbookHistory.data.concat(v.data || [])
      } else {
        state.logbookHistory.data = v.data || []
      }
      state.logbookHistory.total = parseInt(v.total) || 0
    },
    SCHEDULES_SET (state, v) {
      state.schedules.data = v.data || []
      state.schedules.total = parseInt(v.total) || 0
    },
    PILOT_SCHEDULE_SET (state, v) {
      state.pilot_schedule.data = v.data || []
      state.pilot_schedule.total = parseInt(v.total) || 0
    },
    PILOT_AIRCRAFT_SET (state, v) {
      state.pilot_aircraft = v || []
    },
    PILOT_AIRPORT_SET (state, v) {
      state.pilot_airport = v || []
    }
  },
  actions: {
    async ME_GET ({ state, commit, dispatch }) {
      return request.get('/user')
        .then((res) => {
          if (res.status) {
            commit('ME_SET', res.data.data || null)
            dispatch('GET_PILOT_AIRCRAFTS')
            dispatch('GET_PILOT_AIRPORTS')
            dispatch('GET_FLIGHTTIME')
            // if (!parseInt(res.data.data.status)) {
            //   dispatch('ME_UPDATE', { id: parseInt(res.data.data.id), status: 1 })
            // }

            if (navigator.geolocation) {
              if (!state.admin) {
                navigator.geolocation.getCurrentPosition((position) => {
                  dispatch('ME_UPDATE', {
                    id: parseInt(res.data.data.id),
                    pos_latitude: position.coords.latitude,
                    pos_longitude: position.coords.longitude,
                    once: true
                  })
                })
              }
            } else {
              Vue.prototype.$toastNotif({
                message: 'Please allow this app to access your location',
                type: 'info'
              })
            }
            // if (!state.pilot_aircraft.length || !state.logbook.pilots.length) {
            //   dispatch('SYNC_DATA')
            // }
          } else {
            commit('ME_SET', null)
          }
          return res
        })
    },
    async ADMIN_GET ({ state, commit, dispatch }, token) {
      return request.get('/user?admin-token=' + token)
        .then((res) => {
          if (res.status) {
            commit('ADMIN_SET', res.data.data || null)
            if (!state.logbook.pilots.length) {
              dispatch('GET_PILOTS', '?page=1&limit=1000&q=&status=1&admin-token=' + token)
            }
          } else {
            commit('ADMIN_SET', null)
          }
          return res
        })
    },
    async GET_NEWS ({ commit }, q) {
      return request.get('/web/news?visibility=2&status=1' + (q ? `&${q}` : '')).then((res) => {
        if (res.status && res.data.data.response) {
          if (q) {
            if (q.match(/page=1/)) {
              commit('SET_NEWS', res.data.data)
            } else {
              commit('SET_NEWS', Object.assign({ add: true }, res.data))
            }
          }
        } else {
          commit('SET_NEWS', { total: 0, all: [] })
        }
        return res
      }).catch(err => err)
    },
    async ME_UPDATE ({ commit, dispatch }, params) {
      return request.put('/flight/pilot/' + params.id, params)
        .then((res) => {
          if (res.status && !params.once) {
            dispatch('ME_GET')
          }
          return res
        })
    },
    async ME_UPDATE_PASSWORD ({ commit, dispatch }, params) {
      return request.put('/flight/pilot/password', params)
        .then((res) => {
          if (res.status) {
            dispatch('ME_GET')
          }
          return res
        })
    },
    async GET_FLIGHTTIME ({ commit }) {
      return await request.get('/flight/pilot/flighttime')
        .then((res) => {
          if (res.status) {
            const FtForpilots = res.data.data.forpilots || 0
            const FtNewApp = res.data.data.newapp || 0
            const TotalFlightTime = parseFloat(FtForpilots + FtNewApp).toFixed(1)
            commit('ME_FLIGHTTIME_SET', TotalFlightTime)
          } else {
            commit('ME_FLIGHTTIME_SET', 0)
          }
          return res
        })
    },
    async SYNC_DATA ({ dispatch }) {
      await dispatch('GET_AIRCRAFTS', '?page=1&limit=200&q=&status=1')
      await dispatch('GET_AIRPORTS', '?page=1&limit=2000&q=&status=1')
      await dispatch('GET_BASES', '?page=1&limit=1000&q=&status=1')
      await dispatch('GET_AREA', '?page=1&limit=1000&q=&status=1')
    },
    async GET_AIRCRAFTS ({ commit }, q) {
      return await request.get('/flight/fleet' + (q || ''))
        .then(res => {
          if (res.status) {
            const newData = res.data.data.data.map((r) => {
              r.fullname = r.fleet_reg + ' - ' + r.name
              return r
            })
            commit('LOGBOOK_AIRCRAFTS', newData)
          }
        })
        .catch(() => {
          return null
        })
    },
    async GET_AIRPORTS ({ state, commit }, q) {
      function convName (obj) {
        // const r = (obj.iata_code || '-') + '|' + (obj.icao_code || '-') + ' ' + (obj.name ? obj.name : 'no name')
        const IataIcao = (obj.icao_code || obj.iata_code) ? (obj.icao_code || obj.iata_code) : ''
        const r = ((IataIcao.match(/x-/gi) || IataIcao.match(/XX/gi)) ? null : IataIcao + ' - ' + ((obj.city_name || obj.name) ? (obj.city_name || obj.name) : '')) || ((obj.city_name || obj.name) ? (obj.city_name || obj.name) : 'No Name')
        return r
      }
      if (state.logbook.airports.length < 1) {
        return await request.get('/flight/airport' + (q || ''))
          .then(res => {
            if (res.status) {
              const newData = res.data.data.data.map((r) => {
                r.fullairportname = convName(r)
                return r
              })
              commit('LOGBOOK_AIRPORTS', newData)
            }
          })
          .catch(() => {
            return null
          })
      }
    },
    async AIRPORT_GET (context, q) {
      return await request.get('/flight/airport' + (q || '?limit=10000'))
        .then((res) => {
          if (!q) {
            context.commit('AIRPORT_SET', { total: parseInt(res.total) || 0, all: res.data || [] })
          } else {
            context.commit('AIRPORT_SET', res.data ? res : { total: 0, data: [] })
          }
          return res
        })
        .catch(() => {
          return null
        })
    },
    async GET_BASES ({ commit }, q) {
      return await request.get('/flight/base?' + (q || ''))
        .then(res => {
          if (res.status) {
            commit('LOGBOOK_BASES', res.data.data.data)
            commit('BASE_SET', { total: parseInt(res.total) || 0, all: res.data || [] })
          } else {
            commit('LOGBOOK_BASES', [])
          }
        })
        .catch(() => {
          return null
        })
    },
    async GET_AREA ({ commit }, q) {
      return await request.get('/flight/area?' + (q || ''))
        .then(res => {
          if (res.status) {
            commit('LOGBOOK_AREA', res.data.data.data)
          } else {
            commit('LOGBOOK_AREA', [])
          }
        })
        .catch(() => {
          return null
        })
    },
    async GET_PILOT_AIRCRAFTS ({ state, commit }, pid) {
      return await request.get('/pilot/aircraft/' + (pid || state.me.id))
        .then((res) => {
          if (res.status) {
            commit('PILOT_AIRCRAFT_SET', res.data.data || [])
          } else {
            commit('PILOT_AIRCRAFT_SET', [])
          }
        })
        .catch(() => {
          return null
        })
    },
    async GET_PILOT_AIRPORTS ({ state, commit }, pid) {
      return await request.get('/pilot/airport/' + (pid || state.me.id))
        .then((res) => {
          if (res.status) {
            commit('PILOT_AIRPORT_SET', res.data.data || [])
          } else {
            commit('PILOT_AIRPORT_SET', [])
          }
        })
        .catch(() => {
          return null
        })
    },
    async GET_PILOTS ({ commit }, q) {
      return await request.get('/flight/pilot' + (q || ''))
        .then(res => {
          if (res.status) {
            const newData = res.data.data.data.map((r) => {
              r.fullname = r.name + ' ' + r.name_last
              return r
            })
            commit('LOGBOOK_PILOTS', newData)
          }
        })
        .catch(() => {
          return null
        })
    },
    async PILOT_LOGBOOK_GET (context, params) {
      return await request.get('/flight/logbook/pilot/' + params.id + (params.q || ''))
        .then(res => res)
        .catch(() => {
          return null
        })
    },
    async PILOT_SCHEDULES_GET (context, params) {
      return await request.get('/flight/route-schedule/pilot/' + context.state.me.id + (params.q || ''))
        .then((res) => {
          if (params.returnonly) {
            return res
          } else if (res && res.status) {
            context.commit('SCHEDULES_SET', res.data.data)
          } else {
            context.commit('SCHEDULES_SET', { total: 0, data: [] })
          }
          return res
        })
        .catch(() => {
          return null
        })
    },
    async LOGBOOK_GET_ONE (context, id) {
      return await request.get('/flight/logbook/id/' + id)
        .then((res) => res)
        .catch(() => {
          return null
        })
    },
    async LOGBOOK_GET (context, q) {
      q = q || ''
      return await request.get('/flight/logbook?' + (q || ''))
        .then((res) => {
          if (q.match(/noreturn/)) {
            // no return
          } else if (res && res.status) {
            if (q) {
              if (q.match(/page=1/)) {
                context.commit('LOGBOOK_HISTORY', res.data.data)
              } else {
                context.commit('LOGBOOK_HISTORY', Object.assign({ add: true }, res.data.data))
              }
              if (!q.match(/new=1/) && !this.state.logbook.pilots.length) {
                context.dispatch('GET_PILOTS', '?page=1&limit=1000&q=&status=1')
              }
            }
          } else {
            context.commit('LOGBOOK_HISTORY', { total: 0, data: [] })
          }
          return res
        })
        .catch(() => {
          return null
        })
    },
    async LOGBOOK_ADD ({ commit }, params) {
      return await request.post('/flight/logbook', params)
        .then(res => res)
        .catch(() => {
          return null
        })
    },
    async LOGBOOK_UPDATE ({ commit }, params) {
      return await request.put('/flight/logbook/' + params.id, params)
        .then(res => res)
        .catch(() => {
          return null
        })
    },
    async LOGBOOK_DELETE ({ commit }, params) {
      return await request.delete('/flight/logbook/' + params.id)
        .then(res => res)
        .catch(() => {
          return null
        })
    },
    async LOGBOOK_DOWNLOAD ({ commit }, pid) {
      return await request.get('/flight/logbook/download/' + pid)
        .then(res => res)
        .catch(() => {
          return null
        })
    },
    async LOGBOOK_FETCH_DOWNLOAD ({ commit }, url) {
      return fetch(url)
        .then(res => res.blob())
        .catch(e => null)
    },
    async UPLOAD_FILE (context, params) {
      params.source = process.env.NODE_ENV === 'production' ? params.source || 'others' : 'localhost'
      return await request.post('upload', params).then((res) => res)
    },
    async UPLOAD_FILE_DRIVE (context, params) {
      params.source = process.env.NODE_ENV === 'production' ? params.source || 'others' : 'localhost'
      return await request.post('upload/drive', params).then((res) => res)
    },
    async PILOT_SCHEDULE_GET (context, params) {
      return await request.get('/pilot/schedule/' + params.id + (params.q || ''))
        .then((res) => {
          context.commit('PILOT_SCHEDULE_SET', res.status ? res.data.data : { total: 0, data: [] })
          return res
        })
        .catch(() => {
          return null
        })
    },
    async CHECK_T (context, token) {
      return request.get('/user/check/' + token)
        .then((res) => {
          if (res.status) {
            return res.data.data
          }
        })
        .catch(e => null)
    },
    async DO_LOGIN (context, params) {
      return request.post('/login/pilot', params)
        .then((res) => {
          if (res.status) {
            if (res.data.data.response) {
              localStorage.setItem('t', res.data.data.access_token)
              context.dispatch('ME_GET')
            }
          }
          return res
        })
        .catch(e => null)
    },
    async DO_LOGIN_ADMIN (context, params) {
      return request.post('/login', params)
        .then((res) => {
          if (res.status) {
            if (res.data.data.response) {
              localStorage.setItem('t-admin', res.data.data.access_token)
              context.dispatch('ADMIN_GET', res.data.data.access_token)
            }
          }
          return res
        })
        .catch(e => null)
    },
    async DO_LOGIN_PILOT_BY_ADMIN (context, pid) {
      const tokenAdmin = localStorage.getItem('t-admin')
      return request.post('/login-pilot-by-admin/' + pid + '?admin-token=' + tokenAdmin)
        .then((res) => {
          if (res.status) {
            if (res.data.data.response) {
              localStorage.setItem('t', res.data.data.access_token)
              context.dispatch('ME_GET')
              context.dispatch('GET_PILOTS', `?page=1&limit=1000&q=&status=1&admin-token=${tokenAdmin}`)
            }
          }
          return res
        })
        .catch(e => null)
    },
    async ACTION_LOGOUT (context, pid) {
      context.dispatch('ME_UPDATE', {
        id: parseInt(pid),
        web_push: ''
      })

      setTimeout(() => {
        localStorage.removeItem('push_token')
        localStorage.removeItem('t')
        localStorage.removeItem('t-admin')

        context.commit('ADMIN_SET', null)
        context.commit('ME_SET', null)
        context.commit('ME_FLIGHTTIME_SET', 0)
        context.commit('LOGBOOK_AIRCRAFTS', [])
        context.commit('LOGBOOK_PILOTS', [])
        context.commit('LOGBOOK_AIRPORTS', [])
        context.commit('LOGBOOK_BASES', [])
        context.commit('LOGBOOK_AREA', [])
        context.commit('LOGBOOK_HISTORY', { total: 0, data: [] })
        context.commit('SCHEDULES_SET', { total: 0, data: [] })
      }, 200)
    },
    async DO_LOGOUT ({ commit }, pilotOnly) {
      if (!pilotOnly) {
        localStorage.removeItem('push_token')
        localStorage.removeItem('t')
        localStorage.removeItem('t-admin')
        commit('ADMIN_SET', null)
      }
      commit('ME_SET', null)
      commit('ME_FLIGHTTIME_SET', 0)
      commit('LOGBOOK_AIRCRAFTS', [])
      commit('LOGBOOK_PILOTS', [])
      commit('LOGBOOK_AIRPORTS', [])
      commit('LOGBOOK_BASES', [])
      commit('LOGBOOK_AREA', [])
      commit('LOGBOOK_HISTORY', { total: 0, data: [] })
      commit('SCHEDULES_SET', { total: 0, data: [] })
    },
    async AFL_SCHEDULES_GET (context, q) {
      return await request.get('/flight/fleet-afl-schedules' + (q || ''))
        .then(res => res)
        .catch((err) => {
          return {
            status: false,
            data: err
          }
        })
    },
    async AFL_SCHEDULES_PROCESS (context, params) {
      return await request.post('/flight/fleet-afl-schedules', params)
        .then((res) => {
          context.dispatch('ME_GET')
          return res
        })
        .catch((err) => {
          return {
            status: false,
            data: err
          }
        })
    }
  },
  modules
})
