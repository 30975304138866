import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import store from '../store/index'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/history',
    name: 'History',
    component: () => import(/* webpackChunkName: "history" */ '../views/History.vue'),
    meta: { transitionName: 'slide' }
  },
  {
    path: '/schedule',
    name: 'Schedule',
    component: () => import(/* webpackChunkName: "schedule" */ '../views/Schedule.vue'),
    meta: { transitionName: 'slide' }
  },
  {
    path: '/admin',
    name: 'Admin',
    meta: { transitionName: 'slide' }
  },
  {
    path: '/news',
    name: 'News',
    component: () => import(/* webpackChunkName: "news" */ '../views/News.vue'),
    meta: { transitionName: 'slide' }
  },
  {
    path: '/upcoming-schedule',
    name: 'UpcomingSchedule',
    component: () => import(/* webpackChunkName: "upcoming-schedule" */ '../views/UpcomingSchedule.vue'),
    meta: { transitionName: 'slide' }
  },
  {
    path: '/information',
    name: 'Information',
    component: () => import(/* webpackChunkName: "information" */ '../views/Information.vue'),
    meta: { transitionName: 'slide' }
  },
  {
    path: '/airports',
    name: 'Airports',
    component: () => import(/* webpackChunkName: "airports" */ '../views/Airports.vue'),
    meta: { transitionName: 'slide' }
  },
  {
    path: '/contact',
    name: 'Contact',
    component: () => import(/* webpackChunkName: "contact" */ '../views/Contact.vue'),
    meta: { transitionName: 'slide' }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach(async (to, from, next) => {
  if (localStorage.getItem('t') && localStorage.getItem('push_token') && !store.state.admin) {
    store.dispatch('CHECK_T', localStorage.getItem('push_token')).then((res) => {
      if (!res) {
        Vue.prototype.$toastNotif({
          message: 'Oops! Looks like your account sneaked onto another device. Please re-login to get back in action!',
          type: 'info',
          timer: 3500
        })
        store.dispatch('DO_LOGOUT')
        next(false)
      }
    })
  }

  next()
})

export default router
