<template>
  <v-container class="overflow-hidden fill-height pa-0" fluid>
    <SplashScreen />
    <div v-if="pages.onboard" class="container-mobile onboard animate__animated animate__fadeIn animate__faster animate__delay-3s">
      <div class="onboard-card">
        <div class="swiper onboardSwiper">
          <div class="swiper-wrapper">
            <div v-for="(data, index) in onboardContent" class="swiper-slide" :key="index">
              <div class="onboard-title">
                {{ data.title }}
              </div>
              <div class="onboard-caption">
                {{ data.caption }}
              </div>
            </div>
          </div>
          <div class="onboard-pagination" />
          <v-btn v-if="showBtn.next" @click.prevent="onboardSwiper.slideNext()" color="primary" class="onboard-next-btn rounded-pill fill-width">
            Next
          </v-btn>
          <v-btn @click.prevent="switchView()" color="primary" class="onboard-finish-btn rounded-pill fill-width" :class="showBtn.login ? '' : 'd-none'">
            Finish
          </v-btn>
        </div>
      </div>
    </div>
    <div v-if="pages.login" class="container-mobile auth">
      <v-card class="sign-in-card">
        <div class="sign-title">
          Sign In
          <sup v-if="$route.name === 'Admin'">
            as Admin
          </sup>
        </div>
        <div class="sign-caption">
          Please enter your registered account
        </div>
        <div class="sign-form">
          <label class="input-label" for="username">
            {{ $route.name === 'Admin' ? 'E-mail' : 'Username' }}
          </label>
          <v-text-field
            id="username"
            v-model="signin.username"
            :type="$route.name === 'Admin' ? 'email' : 'text'"
            :rules="$route.name === 'Admin' ? [loginRules.validMail] : [loginRules.usernameRequired]"
            outlined
            rounded
            color="primary"
            @keypress.enter="DO_LOGIN()"
          />

          <label class="input-label" for="password">Password</label>
          <v-text-field
            id="password"
            v-model="signin.password"
            :type="showPassword ? 'text' : 'password'"
            :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
            :rules="[loginRules.passwordRequired]"
            outlined
            rounded
            color="primary"
            class="input-password"
            @keypress.enter="DO_LOGIN()"
            @click:append="showPassword = !showPassword"
          />
        </div>
        <div class="font-12 text-right mb-8">
          Need help?
          <v-btn @click.prevent="ContactCRD()" class="sign-help">
            Contact CRD
          </v-btn>
        </div>
        <v-btn
          color="error"
          depressed
          block
          class="btn-login py-7"
          :disabled="!signin.username || !signin.password"
          @click="DO_LOGIN()"
        >
          Sign In {{ $route.name === 'Admin' ? 'as Admin' : '' }}
        </v-btn>

        <div class="sign-separator">
          <span>OR</span>
        </div>

        <div class="sign-login-switch">
          <router-link v-if="$route.name === 'Admin'" to="/">
            Login as Pilot
          </router-link>
          <router-link v-else to="/admin" replace>
            Login as Admin
          </router-link>
        </div>

        <div class="sign-copyright">
          Copyright © {{ new Date().getFullYear() }} PT. ASI Pudjiastuti Aviation
        </div>
      </v-card>
    </div>
  </v-container>
</template>

<script>
import Swiper from 'swiper'
import { Navigation, Pagination, EffectFade } from 'swiper/modules'
import 'swiper/swiper-bundle.css'

export default {
  name: 'Auth',
  data: () => ({
    showBtn: {
      next: true,
      login: false
    },
    pages: {
      onboard: true,
      login: false
    },
    showPassword: false,
    loginRules: {
      validMail: value => !value || /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(value) || 'Please input a valid email',
      usernameRequired: value => !!value || 'Please input your registered username',
      passwordRequired: value => !!value || 'Please input your password'
    },
    signin: {
      username: '',
      password: ''
    },
    onboardSwiper: null,
    onboardContent: [
      {
        title: 'Manage Logbook',
        caption: 'With our app, you can effortlessly update your logbook directly from your schedule, ensuring accurate and up-to-date records with every flight.'
      },
      {
        title: 'Track Schedule and Hours',
        caption: 'Explore flight schedules on every available date with comprehensive flight details and monitor your flight hours with insightful charts.'
      },
      {
        title: 'Realtime Push Notification',
        caption: 'Stay ahead of your schedule with real-time push notifications. Receive instant updates on flight changes and important reminders.'
      }
    ]
  }),
  watch: {
    $route: {
      handler (to, from) {
        if (to) {
          this.signin.username = ''
          this.signin.password = ''
        }
      }
    }
  },
  mounted () {
    this.initSwiper()
  },
  methods: {
    onSwipe () {
      this.showBtn.next = true
      this.showBtn.login = false
    },
    onReachEnd () {
      setTimeout(() => {
        this.showBtn.next = false
        this.showBtn.login = true
      }, 10)
    },
    initSwiper () {
      this.onboardSwiper = new Swiper('.onboardSwiper', {
        modules: [Navigation, Pagination, EffectFade],
        effect: 'fade',
        loop: false,
        spaceBetween: 16,
        pagination: {
          el: '.onboard-pagination'
        }
      })

      this.onboardSwiper.on('slideChange', () => {
        this.onSwipe()
      })

      this.onboardSwiper.on('reachEnd', () => {
        this.onReachEnd()
      })
    },
    switchView () {
      this.pages.onboard = false
      this.pages.login = true
    },
    DO_LOGIN () {
      if (this.$route.name === 'Admin') {
        this.$store.dispatch('DO_LOGIN_ADMIN', this.signin).then((res) => {
          if (res.status) {
            if (res.data.data.response) {
              if (this.$route.name === 'Admin') {
                this.$router.replace('/')
              }
              this.$toastNotif({
                message: 'Logged in',
                type: 'success'
              })
            } else {
              this.$toastNotif({
                message: res.data?.data ? res.data.data.message : 'Ops, something went wrong',
                type: 'error'
              })
            }
          } else {
            this.$toastNotif({
              message: res.data?.data ? res.data.data.message : 'Ops, something went wrong',
              type: 'error'
            })
          }
        })
      } else {
        this.$store.dispatch('DO_LOGIN', this.signin).then((res) => {
          if (res.status) {
            if (res.data.data.response) {
              this.$emit('init-data')
              this.$toastNotif({
                message: 'Logged In',
                type: 'success'
              })
            } else {
              this.$toastNotif({
                message: res.data?.data ? res.data.data.message : 'Ops, something went wrong',
                type: 'error'
              })
            }
          } else {
            this.$toastNotif({
              message: res.data?.data ? res.data.data.message : 'Ops, something went wrong',
              type: 'error'
            })
          }
        })
      }
    },
    OpenWhatsapp () {
      const phone = '628112123933'
      const message = 'Hello...'
      window.open(`https://api.whatsapp.com/send?phone=${phone}&text=${message}`, 'parent')
    },
    ContactCRD () {
      this.$SweetAlert({
        title: 'Need CRD Assistance?',
        caption: 'You will be redirected into Whatsapp message.',
        confirmText: 'Continue',
        onConfirm: this.OpenWhatsapp
      })
    }
  }
}
</script>

<style lang="scss">
@import './../assets/scss/global-vars';
@import './../assets/scss/fonts';
.container-mobile{
  max-width: 500px !important;
  margin: 0 auto;
  background-color: $primary;
  &.onboard:not(.auth){
    position: relative;
    width: 100%;
    height: 100%;
    z-index: 1;
    background-image: url('./../../public/images/media/onboard_bg.jpg');
    background-size: contain;
    .onboard-card{
      position: absolute;
      top: auto;
      right: 0;
      bottom: 0;
      left: 0;
      min-height: 51vh;
      max-height: 75vh;
      width: 100%;
      text-align: center;
      padding: 45px 30px;
      border-radius: 50px 50px 0 0;
      background-color: $white;
      box-shadow: 0px 4px 16px 4px rgba(0, 0, 0, 0.35);
      z-index: 3;
      .onboard-title{
        @include font-size(32px);
        font-weight: 700;
        margin-bottom: 40px;
      }
      .onboard-caption{
        @include font-size(16px);
        margin-bottom: 40px;
      }
      .onboard-next-btn,
      .onboard-finish-btn{
        height: 50px;
        .v-btn__content{
          @include font-size(14px);
          text-transform: capitalize;
          font-weight: 700;
        }
      }
      .onboardSwiper{
        .swiper-slide{
          background-color: $white;
        }
      }
      .onboard-pagination{
        margin: 0 0 30px;
        .swiper-pagination-bullet{
          width: 12px;
          height: 12px;
          margin: 0 6px;
          background-color: #494949;
          &.swiper-pagination-bullet-active{
            background-color: $danger;
          }
        }
      }
    }
  }
  &.auth:not(.onboard) {
    position: relative;
    width: 100%;
    height: 100%;
    z-index: 1;
    background-image: url('./../../public/images/media/signin_bg.jpeg');
    background-size: contain;
    &::before{
      content: '';
      position: absolute;
      top: 16vh;
      right: auto;
      bottom: auto;
      left: 30px;
      width: 124px;
      height: 31px;
      background-image: url('./../../public/images/media/susi_logo.png');
      background-size: 100%;
      z-index: 2;
    }
    .sign-in-card{
      position: absolute;
      top: auto;
      right: 0;
      bottom: 0;
      left: 0;
      height: 77vh;
      overflow: hidden auto;
      width: 100%;
      text-align: left;
      padding: 45px 30px;
      border-radius: 50px 50px 0 0;
      background-color: $white;
      box-shadow: 0px 4px 16px 4px rgba(0, 0, 0, 0.35);
      z-index: 3;
      .sign-title{
        @include font-size(32px);
        font-weight: 700;
        margin-bottom: 14px;
        >sup{
          @include font-size(12px);
          font-weight: 500;
          margin-left: 2px;
          top: -18px;
        }
      }
      .sign-form{
        margin-top: 40px;
      }
      .sign-help{
        height: unset !important;
        background-color: transparent !important;
        padding: 0;
        box-shadow: none;
        @include font-size(12px);
        text-transform: none;
        text-decoration: underline;
        font-weight: 500;
        color: $danger;
      }
      .btn-login {
        border-radius: 50px;
        text-transform: none !important;
        font-weight: 700;
      }
      .sign-separator{
        position: relative;
        text-transform: uppercase;
        text-align: center;
        margin-top: 24px;
        span{
          position: relative;
          background-color: $white;
          padding: 0 8px;
          z-index: 2;
        }
        &::before{
          content: '';
          position: absolute;
          top: 50%;
          left: 50%;
          width: 100%;
          transform: translate(-50%, -50%);
          border-bottom: 1px solid $grey;
          border-radius: 2px;
          z-index: 1;
        }
      }
      .sign-login-switch{
        text-align: center;
        margin-top: 24px;
        >a{
          display: inline-block;
          text-align: center;
          text-decoration: none;
          font-weight: 600;
          color: #494949;
        }
      }
      .sign-copyright{
        text-align: center;
        @include font-size(12px);
        color: $grey;
        margin-top: 52px;
      }
    }
  }
}
</style>
