<template>
  <v-app>
    <OfflineWarn :show="!onLine"/>
    <Auth v-if="!$store.state.me && !$store.state.admin" @init-data="Init()"/>
    <!-- Main Content -->
    <v-main v-if="$store.state.me" :class="[$store.state.me ? 'admin-view' : '']">
      <transition
        :name="transitionName"
        mode="out-in"
      >
        <keep-alive>
          <router-view @init-data="Init()"/>
        </keep-alive>
      </transition>
    </v-main>
    <SelectPilot v-else-if="!$store.state.me && $store.state.admin" @init-data="Init()"/>
    <!-- End of Main Content -->

    <v-dialog
      v-model="dialogEditLegNotes.show"
      persistent
      no-click-animation
      transition="dialog-bottom-transition"
    >
      <v-card class="pa-4 text-center">
        <span class="font-weight-bold">- Edit Leg Notes -</span>
        <v-divider class="my-4" />
        <v-textarea
          v-model="dialogEditLegNotes.notes"
          hide-details
          solo
        />
        <v-btn depressed block color="primary" dark class="mt-4 text-capitalize" @click.prevent="LogbookData.data.routes_obj[dialogEditLegNotes.index].notes = dialogEditLegNotes.notes, dialogEditLegNotes.show = false">
          Update
        </v-btn>
        <v-btn text color="red" dark class="mt-2 text-capitalize" @click.prevent="dialogEditLegNotes.show = false">
          Close
        </v-btn>
      </v-card>
    </v-dialog>

    <!-- Old Logbook Dialog (Forpilots v1) -->
    <v-dialog
      v-model="LogbookData.show"
      fullscreen
      hide-overlay
      persistent
      no-click-animation
      content-class="afl-area"
      transition="dialog-bottom-transition"
    >
      <!-- Dialog Header -->
      <v-app-bar
        color="primary"
        fixed
        class="afl-toolbar"
      >
        <v-toolbar-title class="d-flex fill-width align-center justify-space-between">
          <v-btn @click.prevent="$router.go(-1)" class="back-btn align-self-center">
            <v-icon class="align-middle">
              mdi-chevron-left
            </v-icon>
            <span class="align-middle">Back</span>
          </v-btn>
          <div v-if="LogbookData.data" class="text-right align-self-center">
            <span class="white--text font-18 fw-600">
              {{ parseInt(LogbookData.data.id) ? 'Old Logbook' : 'Create New Logbook' }}
            </span>
          </div>
        </v-toolbar-title>
      </v-app-bar>
      <!-- End of Dialog Header -->

      <v-card :class="[LogbookData.data ? parseInt(LogbookData.data.id) ? 'disabled' : '' : '']" class="afl-card">
        <div class="afl-card-header">
          <flex class="flex-space-beetween align-center">
            <div>
              <div class="d-flex align-center">
                <v-icon small color="primary" class="mr-1">
                  mdi-clock
                </v-icon>
                <span class="font-weight-bold">UTC Time</span>
              </div>
            </div>
            <span>
              <v-switch
                v-model="utcTime"
                color="primary"
                class="mt-0 mb-2"
                hide-details
              ></v-switch>
            </span>
          </flex>
        </div>

        <div class="afl-card-body old-afl-card-body px-0">
          <v-row v-if="LogbookData.data" no-gutters class="pt-3">
            <v-col cols="12">
              <DateTime id="logbookDutyStart" v-model="LogbookData.data.duty_start" :label="{ date: 'Duty Start Date', time: 'Duty Start Time' }" :utc="utcTime" />
            </v-col>
            <v-col cols="12">
              <DateTime id="logbookDutyEnd" v-model="LogbookData.data.duty_end" :label="{ date: 'Duty End Date', time: 'Duty End Time' }" :utc="utcTime" />
            </v-col>
            <v-col cols="12" class="px-6">
              <label class="input-label text-left" for="logbookBase">
                Base
              </label>
              <v-autocomplete
                id="logbookBase"
                v-model.number="LogbookData.data.base"
                :items="$store.state.logbook.bases"
                item-text="name"
                item-value="id"
                hide-details
                outlined
                rounded
                class="mb-4"
                :disabled="$store.state.admin ? true : false"
              />
            </v-col>
            <v-col v-if="!oldFormatRoute && $route.query.day" cols="12" class="px-6">
              <div class="d-flex justify-space-between align-center f-width fill-width">
                <div class="d-flex f-width fill-width align-center">
                  <v-icon small color="primary" class="mr-1">
                    mdi-map-marker-outline
                  </v-icon>
                  <span class="font-weight-bold">Routes</span>
                </div>
                <v-btn x-small depressed color="error" dark class="d-flex align-center text-capitalize rounded-lg" @click.prevent="SyncFromSchedule()">
                  <v-icon small color="white" class="mr-1">
                    mdi-refresh
                  </v-icon>
                  Sync from Schedule
                </v-btn>
              </div>
              <v-divider class="mt-2" />
            </v-col>
            <v-col v-if="oldFormatRoute" cols="12" class="px-6 mb-4">
              <label class="input-label text-left" for="logbookRoutes">
                Routes
              </label>
              <v-textarea
                id="logbookRoutes"
                v-model="LogbookData.data.routes"
                rows="3"
                hide-details
                outlined
                rounded
                class="mb-4"
                :disabled="$store.state.admin ? true : false"
              ></v-textarea>
            </v-col>
            <v-col v-else-if="LogbookData.data.routes_obj.length" cols="12" class="pl-2 pr-4">
              <div class="routes-container">
                <div class="routes-items">
                  <div v-for="(a, iA) in LogbookData.data.routes_obj" :key="'lg-leg-' + iA" class="routes-item">
                    <div class="routes-item-from">
                      <div v-if="a.from" class="route-name">
                        {{ a.from.fullairportname }}
                      </div>
                    </div>
                    <flex class="act">
                      <div class="number">
                        {{ iA + 1 }}
                      </div>
                      <div class="taxi">
                        <label>
                          Taxi Time
                        </label>
                        <select
                          v-model.number="LogbookData.data.routes_obj[iA].perleg"
                          type="text"
                          class="leg-items perleg"
                          @change="countFlighttime(true)"
                        >
                          <option :value="0">
                            0
                          </option>
                          <option :value="0.05">
                            0.05
                          </option>
                          <option :value="0.1">
                            0.1
                          </option>
                          <option :value="0.15">
                            0.15
                          </option>
                          <option :value="0.2">
                            0.2
                          </option>
                          <option :value="0.25">
                            0.25
                          </option>
                          <option :value="0.3">
                            0.3
                          </option>
                        </select>
                      </div>
                      <div class="air">
                        <label>
                          Air Time
                        </label>
                        <select
                          v-model.number="LogbookData.data.routes_obj[iA].airswitch"
                          type="text"
                          class="leg-items airswitch"
                          @change="countFlighttime(true)"
                        >
                          <template v-for="a in 4">
                            <option v-if="a !== 1" :key="'a1-' + a" :value="parseInt(a - 1)">
                              {{ (a - 1) }}
                            </option>
                            <template v-for="aa in 9">
                              <option :key="'a2-' + a + '-a1-' + aa" :value="parseFloat(parseFloat((a - 1) + '.' + aa).toString())">
                                {{ (a - 1) + '.' + aa }}
                              </option>
                              <option :key="'a3-' + a + '-a2-' + aa" :value="parseFloat(parseFloat((a - 1) + '.' + aa + '5').toString())">
                                {{ (a - 1) + '.' + aa + '5' }}
                              </option>
                            </template>
                          </template>
                        </select>
                      </div>
                      <div class="hidden-sm-and-down note grey--text">
                        Est.ETD {{ $localDT(SyncUTC(a.etd), 'time') }}<br>
                        Est.ETA {{ $localDT(SyncUTC(a.eta), 'time') }}
                      </div>
                      <div class="note fo grey--text">
                        <v-autocomplete
                          placeholder="Crew 2"
                          :sss="LogbookData.data.routes_obj[iA].crew2name"
                          v-model.number="LogbookData.data.routes_obj[iA].crew2"
                          :items="withoutMe($store.state.logbook.pilots)"
                          item-text="fullname"
                          item-value="id"
                          hide-details
                          outlined
                          height="30"
                          dense
                          class="rounded-lg"
                          @change="SetCrewName(iA)"
                        />
                      </div>
                    </flex>
                    <div class="pl-6 b-left pb-5">
                      <v-row no-gutters>
                        <v-col cols="8">
                          <v-autocomplete
                            v-model.number="LogbookData.data.routes_obj[iA].aircraft"
                            :items="$store.state.logbook.aircrafts"
                            placeholder="Select Aircraft"
                            item-text="fullname"
                            item-value="id"
                            hide-details
                            outlined
                            height="30"
                            dense
                            class="rounded-lg"
                            @change="SetAircraftName(iA)"
                          />
                        </v-col>
                        <v-col cols="4" class="d-flex align-center px-2">
                          <v-btn :color="LogbookData.data.routes_obj[iA].notes ? 'orange' : 'green'" dark depressed x-small class="text-capitalize" @click.prevent="OpenLegNote(iA)">
                            <v-icon small class="mr-1">
                              mdi-note-edit-outline
                            </v-icon>
                            {{ LogbookData.data.routes_obj[iA].notes ? 'Edit Notes' : 'Add Notes' }}
                          </v-btn>
                        </v-col>
                      </v-row>
                    </div>
                    <div class="routes-item-to">
                      <div v-if="a.to" class="route-name">
                        {{ a.to.fullairportname }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </v-col>
            <v-col cols="12" class="px-6 pb-6">
              <div class="d-flex f-width fill-width align-center">
                <v-icon small color="primary" class="mr-1">
                  mdi-file-outline
                </v-icon>
                <span class="font-weight-bold">Other Informations</span>
              </div>
              <v-divider class="mt-2" />
            </v-col>
            <v-col v-if="oldFormatRoute" cols="6" class="pl-6 pr-2">
              <label class="input-label text-left" for="logbookLegs">
                Legs
              </label>
              <v-text-field
                id="logbookLegs"
                v-model.number="LogbookData.data.legs"
                hide-details
                outlined
                rounded
                class="mb-4"
                @keyup="countFlighttime()"
                @keypress="$NumberOnly($event)"
                :disabled="$store.state.admin ? true : false"
              ></v-text-field>
            </v-col>
            <v-col v-if="oldFormatRoute" cols="6" class="pl-2 pr-6">
              <label class="input-label text-left" for="logbookTaxi">
                Taxi Time
              </label>
              <v-select
                id="logbookTaxi"
                v-model.number="LogbookData.data.legs_per"
                :items="[
                  0.00,
                  0.05,
                  0.10,
                  0.15,
                  0.25,
                  0.25,
                  0.30
                ]"
                hide-details
                outlined
                rounded
                class="mb-4"
                @change="countFlighttime()"
                :disabled="$store.state.admin ? true : false"
              ></v-select>
            </v-col>
            <v-col v-if="oldFormatRoute" cols="6" class="pl-6 pr-2">
              <label class="input-label text-left" for="logbookAirtime">
                Air Time
              </label>
              <v-text-field
                id="logbookAirtime"
                v-model.number="LogbookData.data.airswitch"
                suffix="Hours"
                hide-details
                outlined
                rounded
                class="mb-4"
                @keyup="countFlighttime()"
                @keypress="$NumberOnly($event)"
                :disabled="$store.state.admin ? true : false"
              ></v-text-field>
            </v-col>
            <v-col cols="6" class="pl-2 pr-6">
              <label class="input-label text-left" for="logbookFlighttime">
                Flight Time
              </label>
              <v-text-field
                id="logbookFlighttime"
                v-model="LogbookData.data.flighttime"
                suffix="Hours"
                hide-details
                outlined
                rounded
                disabled
                class="mb-4"
              ></v-text-field>
            </v-col>
            <v-col v-if="LogbookData.data.routes_obj.length === 1" cols="12" class="px-6">
              <label class="input-label text-left" for="logbookFlightN">
                Flight Number
              </label>
              <v-text-field
                id="logbookFlightN"
                v-model="LogbookData.data.flight_number"
                outlined
                rounded
                hide-details
                class="mb-4"
              />
            </v-col>
            <v-row v-if="!LogbookData.data.routes_obj.length" no-gutters>
              <v-col v-if="$store.state.me" cols="12" class="px-6">
                <label class="input-label text-left" for="logbookCrew1">
                  Crew 1
                </label>
                <v-autocomplete
                  id="logbookCrew1"
                  v-model.number="LogbookData.data.crew1"
                  :items="$store.state.logbook.pilots"
                  item-text="fullname"
                  item-value="id"
                  hide-details
                  outlined
                  disabled
                  rounded
                  class="mb-4"
                />
              </v-col>
              <v-col cols="12" class="px-6">
                <label class="input-label text-left" for="logbookCrew2">
                  Crew 2
                </label>
                <v-autocomplete
                  id="logbookCrew2"
                  v-model.number="LogbookData.data.crew2"
                  :items="withoutMe([{ id: 0, fullname: 'None' }].concat($store.state.logbook.pilots))"
                  item-text="fullname"
                  item-value="id"
                  hide-details
                  outlined
                  rounded
                  class="mb-4"
                  :disabled="$store.state.admin ? true : false"
                />
              </v-col>
            </v-row>
            <v-col cols="12" class="px-6">
              <label class="input-label text-left" for="logbookNotes">
                Duty notes or comment
              </label>
              <v-textarea
                id="logbookNotes"
                v-model="LogbookData.data.pilot_notes"
                rows="2"
                hide-details
                outlined
                rounded
                class="mb-4"
                :disabled="$store.state.admin ? true : false"
              />
            </v-col>
            <v-col v-if="false" cols="12" class="px-6 mt-2 mb-6">
              <v-btn
                block
                depressed
                color="error"
                class="rounded-pill py-6 mt-6"
                @click="ProcessLogbook()"
              >
                Update Logbook
              </v-btn>
            </v-col>
          </v-row>
        </div>
      </v-card>
    </v-dialog>
    <!-- End of Old Logbook Dialog -->

    <!-- <FormLeave :show="dialogFormLeaveShow" /> -->
    <DialogAFL v-model="aflShow" />

    <!-- Bottom Navbar -->
    <v-bottom-navigation v-if="$store.state.me" v-model="bottomMenu" fixed grow>
      <v-btn value="home" @click.prevent="$route.name === 'Home' ? '' : $router.replace('/')">
        <span>Home</span>
        <img v-if="$route.name === 'Home'" src="./assets/icons/home_fill.png" alt="Home" width="24" height="24">
        <img v-if="$route.name !== 'Home'" src="./assets/icons/home.png" alt="Home" width="24" height="24">
      </v-btn>
      <v-btn value="schedule" @click.prevent="$route.name === 'Home' ? $route.name === 'Schedule' ? '' : $router.push('/schedule') : $route.name === 'Schedule' ? '' : $router.replace('/schedule')">
        <span>Schedule</span>
        <v-badge
          :value="parseInt($store.state.me.count_schedule) ? true : false"
          :color="parseInt($store.state.me.count_schedule) ? 'red' : 'green'"
          :content="parseInt($store.state.me.count_schedule) || undefined"
          overlap
          bordered
        >
          <img v-if="$route.name === 'Schedule'" src="./assets/icons/calendar_fill.png" alt="Schedule" width="24" height="24">
          <img v-if="$route.name !== 'Schedule'" src="./assets/icons/calendar.png" alt="Schedule" width="24" height="24">
        </v-badge>
      </v-btn>
      <v-btn value="history" @click.prevent="$route.name === 'Home' ? $route.name === 'History' ? '' : $router.push('/history') : $route.name === 'History' ? '' : $router.replace('/history')">
        <span>Logbook</span>
        <v-badge
          :value="parseInt($store.state.me.count_unsigned) ? true : false"
          :color="parseInt($store.state.me.count_unsigned) ? 'red' : 'green'"
          :content="parseInt($store.state.me.count_unsigned) || undefined"
          overlap
          bordered
        >
          <img v-if="$route.name === 'History'" src="./assets/icons/layers_fill.png" alt="History" width="24" height="24">
          <img v-if="$route.name !== 'History'" src="./assets/icons/layers.png" alt="History" width="24" height="24">
        </v-badge>
      </v-btn>
      <v-menu
        top
        right
        :offset-y="menuOffset"
        class="menu-apps"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            dark
            icon
            v-bind="attrs"
            v-on="on"
          >
            <span>More</span>
            <img src="./assets/icons/horizontal_ellipsis.png" alt="More Menu" width="24" height="24">
          </v-btn>
        </template>
        <v-list>
          <v-list-item @click.prevent="$route.name === 'Information' ? $route.name === 'Information' ? '' : $router.push('/information') : $route.name === 'Information' ? '' : $router.replace('/information')">
            <v-list-item-title>
              Pilot Handbook
            </v-list-item-title>
          </v-list-item>
          <v-list-item @click.prevent="ContactCRD()">
            <v-list-item-title>
              CRD Help
            </v-list-item-title>
          </v-list-item>
          <v-list-item @click.prevent="Logout()">
            <v-list-item-title class="red--text">
              Logout
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-bottom-navigation>
    <!-- End of Bottom Navbar -->

  </v-app>
</template>

<script>
import { getMessaging, getToken } from './plugins/firebase-init'
import Auth from './components/Auth'
import { format, parseISO } from 'date-fns'
import 'animate.css'

const DEFAULT_TRANSITION = 'fade'
export default {
  name: 'App',
  components: {
    Auth
  },
  data: () => ({
    onLine: navigator.onLine,
    showBackOnline: false,
    isRefreshLoading: false,
    menuOffset: true,
    // dialogFormLeaveShow: false,
    adminLock: false,
    toggleAdmin: false,
    utcTime: true,
    oldFormatRoute: false,
    transitionName: DEFAULT_TRANSITION,
    bottomMenu: 'home',
    airportSelect: false,
    airportSelected: 0,
    LogbookData: {
      show: false,
      data: null
    },
    dialogEditLegNotes: {
      show: false,
      notes: '',
      index: -1
    },
    aflShow: false
  }),
  watch: {
    onLine (v) {
      if (v) {
        this.showBackOnline = true
        setTimeout(() => {
          this.showBackOnline = false
        }, 1000)
      }
    },
    $route: {
      immediate: true,
      handler (to, from) {
        this.LogbookData.show = false
        this.LogbookData.data = null
        this.aflShow = false
        const logbookID = parseInt(to.query.dialogLogbook)
        if (from && logbookID > 0) {
          const findLogbook = this.$store.state.logbookHistory.data.find(r => parseInt(r.id) === logbookID)
          if (findLogbook) {
            this.openLogbook(findLogbook)
          } else {
            this.$store.dispatch('LOGBOOK_GET_ONE', logbookID).then((res) => {
              if (res.status) {
                this.openLogbook(res.data.data)
              }
            })
          }
        } else if (from && logbookID === 0) {
          this.openLogbook()
        }
        if (to) {
          // if (parseInt(to.query.formLeave) && !this.dialogFormLeaveShow) {
          //   this.dialogFormLeaveShow = true
          // } else {
          //   this.dialogFormLeaveShow = false
          // }
          if (to.query.scheduleDate) {
            this.aflShow = true
          }
        }
        // } else {
        //   this.dialogFormLeaveShow = false
        // }
      }
    },
    airportSelected: function (v) {
      if (v) {
        const findAirports = this.$store.state.logbook.airports.find(r => parseInt(r.id) === parseInt(v))
        if (findAirports) {
          findAirports.airswitch = 0
          findAirports.perleg = 0
          const newAirport = Object.assign({}, findAirports)
          this.LogbookData.data.routes_obj.push(newAirport)
          this.countFlighttime(true)
        }
      }
      this.airportSelected = 0
      this.airportSelect = false
    }
  },
  created () {
    this.Init()
    const tokenAdmin = localStorage.getItem('t-admin')
    if (tokenAdmin) {
      this.$store.dispatch('ADMIN_GET', tokenAdmin).then((res) => {
        if (res.status && this.$route.name === 'Admin') {
          this.$router.replace('/')
        }
      })
    }
    if (parseInt(this.$route.query.editprofile)) {
      this.$router.go(-1)
    }
    this.$router.beforeEach((to, from, next) => {
      let transitionName = to.meta.transitionName || from.meta.transitionName
      if (transitionName === 'slide') {
        if (to.name === 'Home') {
          transitionName = 'slide-right'
        } else if (from.name === 'History') {
          transitionName = 'slide-right'
        } else {
          transitionName = 'slide-left'
        }
      }

      this.transitionName = transitionName || DEFAULT_TRANSITION
      next()
    })
  },
  mounted () {
    window.addEventListener('online', this.updateOnlineStatus)
    window.addEventListener('offline', this.updateOnlineStatus)
  },
  beforeDestroy () {
    window.removeEventListener('online', this.updateOnlineStatus)
    window.removeEventListener('offline', this.updateOnlineStatus)
  },
  methods: {
    updateOnlineStatus (e) {
      const { type } = e
      this.onLine = type === 'online'
      if (type === 'online') {
        this.$toastNotif({
          message: 'You are back online',
          type: 'success'
        })
        this.isRefreshLoading = false
        if (localStorage.getItem('t') && localStorage.getItem('push_token') && !this.$store.state.admin) {
          this.$store.dispatch('CHECK_T', localStorage.getItem('push_token')).then((res) => {
            if (!res) {
              this.$toastNotif({
                message: 'Oops! Looks like your account sneaked onto another device. Please re-login to get back in action!',
                type: 'info',
                timer: 3500
              })
              this.$store.dispatch('DO_LOGOUT')
            }
          })
        }
      } else {
        this.$toastNotif({
          message: 'Oops.. your connection lost',
          type: 'info'
        })
      }
    },
    refreshConnection () {
      this.isRefreshLoading = true
    },
    ShowFormList () {
      this.$router.push('?formLeave=1')
      // this.dialogFormLeave.show = true
    },
    SyncFromSchedule () {
      if (this.$route.query.day) {
        this.loadSchedulesDay({
          from: this.$route.query.day,
          to: this.$route.query.day
        })
      } else {
        this.$toastNotif({
          message: 'Ops, something went wrong with date. Please reload the application!',
          type: 'info'
        })
      }
    },
    async loadSchedulesDay (toFrom) {
      const currDate = this.$TodayDate()
      await this.$store.dispatch('PILOT_SCHEDULES_GET', { q: '?sort=ASC&from=' + (toFrom ? toFrom.from : this.$DateDiffDays(currDate, this.showDaysPrev)) + '&to=' + (toFrom ? toFrom.to : this.$DateAddDays(currDate, this.showDaysNext)), returnonly: true }).then(async (r) => {
        if (r.status && r.data.data.data) {
          // this.LogbookData.data.routes_obj
          // Sync routes_obj and loaded schedule on selected date day
          const lgbk = Object.assign({}, this.LogbookData.data)
          const routesBefore = lgbk.routes_obj || []
          let syncedSchedules = r.data.data.data || []
          syncedSchedules = await this.$sortArray(syncedSchedules.map((r) => {
            r.etdts = this.$dtTimestamp(this.$localDT(r.etd, 'datetimedefault'))
            return r
          }), 'etdts')
          this.LogbookData.data.legs = syncedSchedules.length - 1
          this.LogbookData.data.schedule_multiple = syncedSchedules.length - 1
          const newdutystart = new Date(syncedSchedules[0].etd)
          const newdutyend = new Date(syncedSchedules[syncedSchedules.length - 1].eta)
          this.LogbookData.data.routes_obj = []
          await syncedSchedules.map(async (r, rindex) => {
            let f = routesBefore.find(rx => parseInt(rx.sid) === parseInt(r.id))
            if (f || (routesBefore.length > 0 && rindex < (routesBefore.length - 1))) {
              f = f || Object.assign({}, routesBefore[rindex])
              f.sid = parseInt(r.id)
            } else if (!f) {
              f = this.GenerateRoute(r)
              this.LogbookData.data.airswitch += f.airswitch
              this.LogbookData.data.legs_per += f.perleg
              if (!lgbk.aircraft_ids.match(new RegExp('"' + parseInt(f.aircraft) + '"')) && parseInt(f.aircraft)) {
                this.LogbookData.data.aircraft_ids += '"' + (parseInt(f.aircraft) || 0) + '",'
              }
              if (!lgbk.routes_ids.match(new RegExp('"' + parseInt(f.sid) + '"')) && parseInt(f.sid)) {
                this.LogbookData.data.routes_ids += '"' + (parseInt(f.sid) || 0) + '",'
              }
            }
            this.LogbookData.data.routes_obj.push(f)
            this.countFlighttime(true)
          })
          if (syncedSchedules.length > 1) {
            newdutystart.setMinutes(newdutystart.getMinutes() - 40)
            newdutyend.setMinutes(newdutyend.getMinutes() + 20)
          }
          this.LogbookData.data.duty_start = newdutystart.toISOString()
          this.LogbookData.data.duty_end = newdutyend.toISOString()
          setTimeout(() => {
            this.$toastNotif({
              message: 'Sync successfully!',
              type: 'success'
            })
          }, 150)
        }
      })
    },
    Init () {
      this.$store.dispatch('ME_GET').then((r) => {
        if (r.status && r.data.data.id) {
          this.$store.dispatch('SYNC_DATA')

          const user = r.data.data

          if (localStorage.getItem('push_token') === null && user.role === 'pilot') {
            const messaging = getMessaging()

            getToken(messaging, { vapidKey: user.vapid_public_key }).then((currentToken) => {
              if (currentToken) {
                localStorage.setItem('push_token', btoa(currentToken))
                this.$store.dispatch('ME_UPDATE', {
                  id: parseInt(user.id),
                  web_push: btoa(currentToken),
                  once: true
                })
              }
            }).catch((err) => {
              console.error('An error occurred while retrieving token. ', err)
            })
          }
        }
      })
    },
    SyncUTC (etaetd) {
      let dt = ''
      let t = ''
      if (this.utcTime) {
        dt = format(parseISO(new Date(this.$getUTC(etaetd)).toISOString()), 'yyyy-MM-dd')
        t = this.$localDT(this.$getUTC(etaetd), 'time')
      } else {
        dt = format(parseISO(new Date(etaetd).toISOString()), 'yyyy-MM-dd')
        t = this.$localDT(etaetd, 'time')
      }
      let res = dt + ' ' + t
      if (this.utcTime) {
        res = res.trim().replace(' ', 'T') + ':00.000Z'
      }
      return res
    },
    countFlighttime (newFormat) {
      if (newFormat) {
        let ft = 0
        for (let a = 0; a < this.LogbookData.data.routes_obj.length; a++) {
          ft += (parseFloat(this.LogbookData.data.routes_obj[a].airswitch || 0) + parseFloat(this.LogbookData.data.routes_obj[a].perleg || 0))
        }
        // this.LogbookData.data.routes_obj.map((r, index) => {
        //   return r
        // })
        this.LogbookData.data.flighttime = ft.toFixed(1)
      } else {
        const airswitch = parseFloat(this.LogbookData.data.airswitch) || 0
        const perleg = parseFloat(this.LogbookData.data.legs_per).toFixed(2) || 0
        const legs = parseInt(this.LogbookData.data.legs) || 0
        this.LogbookData.data.flighttime = (airswitch + (perleg * legs)).toFixed(1)
      }
    },
    withoutMe (p) {
      return [{ id: 0, fullname: 'None' }].concat(p.filter(r => parseInt(r.id) !== parseInt(this.$store.state.me.id)))
    },
    removeRoute (index) {
      if (!confirm('Remove route ?')) {
        return false
      }
      this.LogbookData.data.routes_obj.splice(index, 1)
      this.countFlighttime(true)
    },
    async openLogbook (loogbook) {
      // logbook = Data Logbook base On query logbook id or from ID logbook by id logbook
      this.ClearLogbook()
      let exportSchedules = null
      if (loogbook) {
        const lgbk = await Object.assign({}, loogbook)
        lgbk.routes_ids = ''
        lgbk.aircraft_ids = ''
        if (lgbk.routes_obj) {
          lgbk.routes_obj = await this.$parseRoutes(lgbk.routes_obj).map((r) => {
            r.sid = r.sid || 0
            r.perleg = parseFloat(r.perleg ? r.perleg.toString() : r.perleg)
            r.airswitch = parseFloat(r.airswitch ? r.airswitch.toString() : r.airswitch)
            if (!r.crew2) {
              r.crew2 = parseInt(lgbk.crew2) || 0
              r.crew2name = this.SetCrewName(0, { id: parseInt(lgbk.crew2) })
              r.pic = (parseInt(lgbk.captain) === parseInt(r.crew1) ? parseInt(r.crew1) : parseInt(r.crew2)) || 0
              r.fo = (parseInt(lgbk.captain) === parseInt(r.crew1) ? parseInt(r.crew2) : parseInt(r.crew1)) || 0
              r.pic_name = this.SetCrewName(0, { id: parseInt(r.pic) })
              r.fo_name = this.SetCrewName(0, { id: parseInt(r.fo) })
            }
            r.aircraft = parseInt(r.aircraft) || parseInt(lgbk.aircraft) || 0
            // r.aircraft_name = this.SetAircraftName(0, { id: parseInt(r.aircraft) })
            lgbk.routes_ids += r.sid ? '"' + r.sid + '",' : (lgbk.routes_ids || '')
            if (!lgbk.aircraft_ids.match(new RegExp('"' + r.aircraft + '"'))) {
              lgbk.aircraft_ids += r.aircraft ? '"' + r.aircraft + '",' : (lgbk.aircraft_ids || '')
            }
            return r
          }) || []
          this.oldFormatRoute = false
        } else {
          this.oldFormatRoute = true
        }
        this.LogbookData.data = await Object.assign({}, lgbk)
        this.LogbookData.data.legs = parseInt(this.LogbookData.data.legs)
        this.LogbookData.data.flighttime = parseFloat(this.LogbookData.data.flighttime).toFixed(1)
        this.LogbookData.data.airswitch = parseFloat(this.LogbookData.data.airswitch)
        this.LogbookData.data.airswitch = parseFloat(this.LogbookData.data.airswitch ? this.LogbookData.data.airswitch.toString() : this.LogbookData.data.airswitch)
        this.LogbookData.data.legs_per = parseFloat(((this.LogbookData.data.flighttime - this.LogbookData.data.airswitch) / this.LogbookData.data.legs).toFixed(2))
      } else {
        this.oldFormatRoute = false
        // Check exist exported data from schedule
        let LogbookDataFromAPI = null
        if (this.$store.state.exportSchedule) {
          if (this.$store.state.exportSchedule.data.length === 1) {
            const logbookID = parseInt(this.$store.state.exportSchedule.data[0].flight_logbook_id)
            if (logbookID) {
              const getExistingLogbook = await this.$store.dispatch('LOGBOOK_GET_ONE', logbookID)
              if (getExistingLogbook) {
                if (getExistingLogbook.status && parseInt(getExistingLogbook.data.data.id)) {
                  LogbookDataFromAPI = await Object.assign({}, getExistingLogbook.data.data)
                }
              }
            }
          }
          exportSchedules = await Object.assign({}, this.$store.state.exportSchedule)
          await this.$store.commit('EXPORT_SCHEDULE', null)
        }
        if (LogbookDataFromAPI) {
          this.openLogbook(LogbookDataFromAPI)
          return false
        } else {
          const newLogbook = {
            id: null,
            schedule: exportSchedules.data.length === 1 ? exportSchedules ? parseInt(exportSchedules.data[0].id) : 0 : 0,
            schedule_multiple: exportSchedules.data.length || 1,
            crew1: parseInt(this.$store.state.me.id),
            crew2: 0,
            flight_number: '',
            captain: parseInt(this.$store.state.me.id),
            captain_me: 'Yes',
            aircraft: 0,
            base: 0,
            duty_date: '',
            duty_start: '',
            duty_end: '',
            airswitch: 0.0,
            legs: 0,
            legs_per: 0.05,
            flighttime: 0,
            turn: 0,
            routes: '',
            routes_obj: [],
            routes_ids: '',
            aircraft_ids: '',
            pilot_notes: '',
            status: 1
          }

          if (parseInt(this.$store.state.me.aircraft)) {
            newLogbook.aircraft = parseInt(this.$store.state.me.aircraft)
          }
          if (parseInt(this.$store.state.me.duty_base)) {
            newLogbook.base = parseInt(this.$store.state.me.duty_base)
          }

          let isCaptain = false
          if (exportSchedules) {
            const sortedSchedules = await this.$sortArray(exportSchedules.data.map((r, indx) => {
              r.etdts = this.$dtTimestamp(this.$localDT(r.etd, 'datetimedefault'))
              return r
            }), 'etdts')
            newLogbook.routes = ''
            newLogbook.airswitch = 0
            newLogbook.legs = await sortedSchedules.length - 1
            newLogbook.legs_per = 0.05
            newLogbook.routes_obj = []
            newLogbook.routes_ids = ''
            newLogbook.aircraft_ids = ''
            await sortedSchedules.map((r, indx) => {
              newLogbook.routes_obj.push(this.GenerateRoute(r))
              newLogbook.routes_ids += '"' + (parseInt(r.id) || 0) + '",'
              newLogbook.aircraft_ids += '"' + (parseInt(r.aircraft) || 0) + '",'
              return r
            })
            // newLogbook.crew2 = crew2
            newLogbook.aircraft = await sortedSchedules[0].aircraft
            newLogbook.base = await sortedSchedules[0].base_airport
            newLogbook.duty_start = new Date(sortedSchedules[0].etd)
            newLogbook.duty_end = new Date(sortedSchedules[sortedSchedules.length - 1].eta)
            if (newLogbook.routes_obj.length) {
              await newLogbook.duty_start.setMinutes(newLogbook.duty_start.getMinutes() - 40)
              await newLogbook.duty_end.setMinutes(newLogbook.duty_end.getMinutes() + 20)
            }
            newLogbook.duty_start = await newLogbook.duty_start.toISOString()
            newLogbook.duty_end = await newLogbook.duty_end.toISOString()

            isCaptain = parseInt(sortedSchedules[0].pilot) === parseInt(this.$store.state.me.id)
          }
          this.LogbookData.data = newLogbook
          if (isCaptain) {
            this.LogbookData.data.captain_me = 'Yes'
          } else {
            this.LogbookData.data.captain_me = 'No'
          }
        }
      }
      this.LogbookData.data.flighttime = parseFloat(this.LogbookData.data.flighttime).toFixed(1)

      this.countFlighttime(!this.oldFormatRoute)
      this.LogbookData.show = true
    },
    GenerateRoute (r) {
      const crew2 = (parseInt(this.$store.state.me.id) === parseInt(r.pilot)) ? (parseInt(r.copilot) || 0) : (parseInt(r.pilot) || 0)
      const aircraftSelected = this.$store.state.logbook.aircrafts.find(a => parseInt(a.id) === parseInt(r.aircraft))
      const newRoute = {
        sid: parseInt(r.id) || 0, // schedule ID
        from: {
          id: r.route_dep || 0,
          shortname: this.$convertShortName(r, 'dep'),
          fullairportname: this.$convertFullName(r, 'dep'),
          iata_code: r.dep_iata || '',
          icao_code: r.dep_icao || ''
        },
        to: {
          id: r.route_arr || 0,
          shortname: this.$convertShortName(r, 'arr'),
          fullairportname: this.$convertFullName(r, 'arr'),
          iata_code: r.arr_iata || '',
          icao_code: r.arr_icao || ''
        },
        etd: r.duty_local_date + 'T' + r.etd_local_time + ':00',
        eta: r.duty_local_date + 'T' + r.eta_local_time + ':00',
        airswitch: parseFloat(r.flighttime_decimal ? r.flighttime_decimal.toString() : r.flighttime_decimal),
        perleg: 0,
        crew2: crew2,
        crew2name: parseInt(this.$store.state.me.id) === parseInt(r.pilot) ? r.copilot_name ? (r.copilot_name + ' ' + r.copilot_lastname) : '-' : r.pilot_name ? (r.pilot_name + ' ' + r.pilot_lastname) : '-',
        pic: parseInt(r.pilot) || 0,
        fo: parseInt(r.copilot) || 0,
        pic_name: r.pilot_name ? (r.pilot_name + ' ' + r.pilot_lastname) : '',
        fo_name: r.copilot_name ? (r.copilot_name + ' ' + r.copilot_lastname) : '',
        aircraft: aircraftSelected ? parseInt(aircraftSelected.id) || 0 : 0,
        aircraft_name: r.aircraft_name ? r.aircraft_name : (aircraftSelected ? aircraftSelected.fleet_reg + ' - ' + aircraftSelected.name : ''),
        notes: ''
      }
      newRoute.airswitch = parseFloat(newRoute.airswitch ? newRoute.airswitch.toString() : newRoute.airswitch)
      if (aircraftSelected) {
        if (parseInt(aircraftSelected.type) === 4) {
          // pilatus
          newRoute.perleg = 0.05
        } else {
          newRoute.perleg = 0.1
        }
      }
      return newRoute
    },
    ProcessLogbook () {
      const Logbook = Object.assign({}, this.LogbookData.data)
      Logbook.legs = this.LogbookData.data.routes ? parseInt(Logbook.legs) : this.LogbookData.data.routes_obj.length
      if (new Date(Logbook.duty_start) >= new Date(Logbook.duty_end)) {
        this.$toastNotif({
          message: Logbook.routes ? 'Duty end datetime must be greater than duty start datetime' : 'Arrival datetime must be greater than Departure datetime',
          type: 'info'
        })
        return false
      }
      if (!parseInt(Logbook.base)) {
        this.$toastNotif({
          message: 'Please select base',
          type: 'info'
        })
        return false
      }
      if (Logbook.routes) {
        this.countFlighttime(false)
        Logbook.routes_obj = ''
        if (!parseInt(Logbook.legs)) {
          this.$toastNotif({
            message: 'Legs cannot be 0',
            type: 'info'
          })
          return false
        }
        if (!parseFloat(Logbook.airswitch)) {
          this.$toastNotif({
            message: 'Airswitch cannot be 0',
            type: 'info'
          })
          return false
        }
      } else if (Logbook.routes_obj.length) {
        this.countFlighttime(true)
        Logbook.routes = ''
        Logbook.airswitch = 0
        Logbook.legs_per = 0
        Logbook.routes_ids = ''
        Logbook.aircraft_ids = ''
        Logbook.schedule_multiple = Logbook.routes_obj.length || 0
        Logbook.routes_obj = this.$JsonToStr(Logbook.routes_obj.map((rr) => {
          Logbook.routes_ids += rr.sid ? '"' + rr.sid + '",' : (Logbook.routes_ids || '')
          if (!Logbook.aircraft_ids.match(new RegExp('"' + rr.aircraft + '"'))) {
            Logbook.aircraft_ids += rr.aircraft ? '"' + rr.aircraft + '",' : (Logbook.aircraft_ids || '')
          }
          const p = this.$store.state.logbook.aircrafts.find(r => parseInt(r.id) === parseInt(rr.aircraft))
          if (p) {
            rr.aircraft_name = p.fleet_reg + ' - ' + p.name
          }
          return rr
        }))
        Logbook.duty_date = this.$localDT(this.SyncUTC(Logbook.duty_start), 'datedefault')
      } else {
        this.$toastNotif({
          message: 'Please add routes',
          type: 'info'
        })
        return false
      }

      // DUTY AND FT LIMIT
      if ((new Date(Logbook.duty_end) - new Date(Logbook.duty_start)) > (3600000 * 12)) {
        this.$toastNotif({
          message: 'Duty time maximum is 12 hours/day',
          type: 'info'
        })
        return false
      }
      if ((parseFloat(Logbook.flighttime) * 3600000) > (new Date(Logbook.duty_end) - new Date(Logbook.duty_start))) {
        this.$toastNotif({
          message: 'Your flighttime cannot exceed duty time!',
          type: 'error'
        })
        return false
      }

      if (parseInt(Logbook.crew2)) {
        if (Logbook.captain_me === 'Yes') {
          Logbook.captain = parseInt(Logbook.crew1)
        } else {
          Logbook.captain = parseInt(Logbook.crew2)
        }
      } else {
        Logbook.captain = parseInt(Logbook.crew1)
      }

      if (parseInt(Logbook.id)) {
        this.$store.dispatch('LOGBOOK_UPDATE', Logbook).then((res) => {
          if (res.status) {
            this.$store.dispatch('LOGBOOK_GET', 'page=1&limit=30')
            this.$toastNotif({
              message: 'Logbook Updated',
              type: 'success'
            })
            this.$router.go(-1)
          } else {
            this.$toastNotif({
              message: 'Something went wrong',
              type: 'error'
            })
          }
        })
      } else {
        Logbook.id = null
        delete Logbook.id
        this.$store.dispatch('LOGBOOK_ADD', Logbook).then((res) => {
          if (res.status && res.data.data) {
            this.$store.dispatch('LOGBOOK_GET', 'page=1&limit=30')
            this.$toastNotif({
              message: 'Logbook Created',
              type: 'success'
            })
            this.$router.go(-1)
          } else if (res.status && !res.data.data) {
            this.$toastNotif({
              message: 'The duty on the date already exists',
              type: 'error'
            })
          } else {
            this.$toastNotif({
              message: 'Something went wrong',
              type: 'error'
            })
          }
        })
      }
    },
    SetCrewName (index, data) {
      if (data) {
        const p = this.$store.state.logbook.pilots.find(r => parseInt(r.id) === data.id)
        return p ? ((p.name + ' ' + p.name_last) || '-') : '-'
      } else {
        const p = this.$store.state.logbook.pilots.find(r => parseInt(r.id) === parseInt(this.LogbookData.data.routes_obj[index].crew2))
        if (p) {
          this.LogbookData.data.routes_obj[index].crew2name = (p.name + ' ' + p.name_last) || '-'
        } else {
          this.LogbookData.data.routes_obj[index].crew2name = '-'
        }
      }
    },
    SetAircraftName (index, data) {
      const SelectedAircraft = this.LogbookData.data.routes_obj[index].aircraft || 0
      if (SelectedAircraft) {
        if (data) {
          const p = this.$store.state.logbook.aircrafts.find(r => parseInt(r.id) === data.id)
          if (p) {
            if (parseInt(p.type) === 1) {
              // CESSNA
              // LogbookData.data.routes_obj[iA].aircraft
              this.LogbookData.data.routes_obj[index].perleg = 0.1
            } else if (parseInt(p.type) === 4) {
              this.LogbookData.data.routes_obj[index].perleg = 0.05
            }
          }
          return p ? ((p.fleet_reg + ' - ' + p.name) || '') : '-'
        } else {
          const p = this.$store.state.logbook.aircrafts.find(r => parseInt(r.id) === parseInt(SelectedAircraft))
          if (p) {
            this.LogbookData.data.routes_obj[index].aircraft_name = (p.fleet_reg + ' - ' + p.name) || ''
            if (parseInt(p.type) === 1) {
              // CESSNA
              // LogbookData.data.routes_obj[iA].aircraft
              this.LogbookData.data.routes_obj[index].perleg = 0.1
            } else if (parseInt(p.type) === 4) {
              this.LogbookData.data.routes_obj[index].perleg = 0.05
            }
          } else {
            this.LogbookData.data.routes_obj[index].aircraft_name = ''
          }
        }
      }
    },
    OpenLegNote (index) {
      this.dialogEditLegNotes.index = index
      this.dialogEditLegNotes.notes = this.LogbookData.data.routes_obj[index].notes || ''
      this.dialogEditLegNotes.show = true
    },
    ClearLogbook () {
      this.airports = []
      this.airportSelect = false
      this.airportSelected = 0
    },
    OpenWhatsapp () {
      const phone = '628112123933'
      const message = 'Hello...'
      window.open(`https://api.whatsapp.com/send?phone=${phone}&text=${message}`, 'parent')
    },
    ContactCRD () {
      this.$SweetAlert({
        title: 'Need CRD Assistance?',
        caption: 'You will be redirected into Whatsapp message.',
        confirmText: 'Continue',
        onConfirm: this.OpenWhatsapp
      })
    },
    Logout () {
      this.$SweetAlert({
        title: 'Logout?',
        caption: 'Goodbye! Until we meet again, take care and stay sweet!.',
        onConfirm: this.ProceedLogout
      })
    },
    ProceedLogout () {
      this.$toastNotif({
        message: 'May your path ahead be sprinkled with serenity and joy until we meet again.',
        type: 'success'
      })

      this.$store.dispatch('ACTION_LOGOUT', this.$store.state.me.id)
      if (this.$store.state.admin) {
        this.$router.replace('/admin')
      }
    }
  }
}
</script>
<style lang="scss">
@import '../src/assets/scss/apps';

.admin-lock {
  position: fixed!important;
  z-index: 10;
  bottom: 70px;
  right: 20px;
  .v-card {
    width: 48px;
    &.active {
      width: 220px;
    }
  }
  .todrag {
    width: 48px;
    height: 48px;
    cursor: move;
    i {
      height: 100%;
      display: block;
    }
  }
}
#app {
  position: relative;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  background: #fafafa;
  height: 100%;
  overflow: auto hidden;
  .admin-view{
    position: inherit;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    max-width: 500px;
  }
}
.v-btn--fixed {
  &.v-btn--bottom {
    bottom: 75px!important;
  }
}
.v-bottom-navigation {
  background-color: $white;
  left: 50% !important;
  transform: translateX(-50%) !important;
  width: 100% !important;
  max-width: 500px !important;
  padding: 10px 0 18px;
  height: auto !important;
  border-radius: 20px 20px 0 0;
  z-index: 203 !important;
  .v-btn:not(.v-btn--text):not(.v-btn--outlined):hover:before{
    opacity: 0 !important;
  }
  .v-btn__content {
    span {
      letter-spacing: 0px!important;
    }
  }
}
.fade-enter-active,
.fade-leave-active {
  transition-duration: 0.2s;
  transition-property: opacity;
  transition-timing-function: ease;
}

.fade-enter,
.fade-leave-active {
  opacity: 0
}
.slide-left-enter-active,
.slide-left-leave-active,
.slide-right-enter-active,
.slide-right-leave-active {
  transition-duration: 0.3s;
  transition-property: height, opacity, transform;
  transition-timing-function: cubic-bezier(0.33, 0, 0.1, 1);
  overflow: hidden;
}

.slide-left-enter,
.slide-right-leave-active {
  opacity: 0;
  transform: translate(2em, 0);
}

.slide-left-leave-active,
.slide-right-enter {
  opacity: 0;
  transform: translate(-2em, 0);
}
.cpointer {
  cursor: pointer;
}

.routes-container {
  position: relative;
  padding: 20px 0px 0px 10px;
  >.routes-items {
    .b-left {
      border-left: solid 1px #aaa;
      // border-top: solid 1px #aaa;
      // border-bottom: solid 1px #aaa;
    }
    >.routes-item {
      position: relative;
      margin-bottom: 10px;
      &::before {
        content: "";
        position: absolute;
        top: 0px;
        left: 0px;
        right: -10px;
        bottom: 45px;
        background: rgba(253, 167, 54, 0.1);
        border-radius: 16px;
      }
      >.routes-item-from,
      >.routes-item-to {
        position: relative;
        height: 65px;
        border-top: solid 1px #aaa;
        border-left: solid 1px #aaa;
        >.route-name {
          position: absolute;
          top: -16px;
          padding: 4px 35px 4px 12px;
          @include font-size(14px);
          border-radius: 10px;
          border: solid 1px #aaa;
          left: 10px;
          right: 0px;
          width: auto;
          background: #fff;
          >button,
          >i {
            position: absolute;
            top: 0px;
            right: 0px;
            height: 100%;
            padding: 2px 10px;
            @include font-size(14px);
            color: #CC0000;
            cursor: pointer;
          }
        }
      }
      >.routes-item-to {
        border-left: none;
        height: 45px;
      }
      >.act {
        position: absolute;
        left: -14px;
        width: calc(100% + 14px);
        top: 21px;
        height: auto;
        >.number {
          line-height: 100%;
          padding: 6px;
          width: 30px;
          margin-bottom: -35px;
          text-align: center;
          height: 30px;
          border-radius: 50%;
          background-color: #2f31d6;
          color: #FFF;
        }
        >.taxi,
        >.air,
        >.note {
          position: relative;
          margin-left: 10px;
          width: 70px;
          &.fo {
            min-width: calc(100% - 285px);
            @media only screen and (max-width: 767px) {
              min-width: calc(100% - 200px);
            }
          }
          >label {
            position: absolute;
            top: -5px;
            right: 0px;
            pointer-events: none;
            color: #2f31d6;
            padding: 0px 5px;
            background: #fff;
            font-size: 10px;
          }
          >input,
          >select {
            display: block;
            width: 100%;
            border-radius: 4px;
            outline: none;
            border: solid 1px #2f31d6;
            padding: 2px 8px;
          }
        }
        >.note {
          font-size: 11px;
        }
      }
    }
  }
}
.file-uploader {
  position: absolute;
  display: block;
  overflow: hidden;
  left: -100px;
  bottom: -100px;
  height: 0px;
  width: 0px;
  z-index: -10;
}
.admin-view {
  padding-bottom: 70px!important;
}
.card-area-logbook {
  &.disabled {
    position: relative;
  }
}
.fill-width {
  width: 100%!important;
}
.half-width {
  width: 50% !important;
}
.nav-bottom-center {
  position: relative;
  display:flex;
  width: 100%!important;
  align-items: center;
  align-content: center;
  justify-items: center;
  justify-content: center;
  span {
    position: absolute;
    bottom: 25px;
    padding: 12px 0px;
    text-align: center;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    box-shadow: 0px -5px 20px -5px rgba(0, 30, 85, .3)!important;
  }
}
.caption-small {
  font-size:0.55rem!important;
}
.v-menu__content{
  border-radius: 20px !important;
  padding: 0;
}
.old-afl-card-body{
  height: calc(100vh - 270px) !important;
}
</style>
