import Vue from 'vue'
import Swal from 'sweetalert2'
import moment from 'moment'

const PullRefresh = () => import(/* webpackChunkName: "pull-refresh" */ '@/components/PullRefresh.vue')
const OfflineWarn = () => import(/* webpackChunkName: "offline-warning" */ '@/components/OfflineWarning.vue')
const CloudBackground = () => import(/* webpackChunkName: "cloud-backgroud" */ '@/components/CloudBackground.vue')
const SelectPilot = () => import(/* webpackChunkName: "select-pilot" */ '@/components/SelectPilot.vue')
const ChromeOnly = () => import(/* webpackChunkName: "chrome-only" */ '@/components/ChromeOnly.vue')
const SplashScreen = () => import(/* webpackChunkName: "splashscreen" */ '@/components/SplashScreen.vue')
const FormLeave = () => import(/* webpackChunkName: "form-leave" */ '@/components/Forms/FormLeave.vue')
const FormLeaveApp = () => import(/* webpackChunkName: "form-leave-app" */ '@/components/Forms/FormLeaveApp.vue')
const Flex = () => import(/* webpackChunkName: "flex" */ '@/components/Flex.vue')
const Loading = () => import(/* webpackChunkName: "loading" */ '@/components/Loading.vue')
const LittleLoading = () => import(/* webpackChunkName: "little-loading" */ '@/components/LittleLoading.vue')
const ChartLimit = () => import(/* webpackChunkName: "chart-limit" */ '@/components/ChartLimit.vue')
const ChartLimitation = () => import(/* webpackChunkName: "chart-limitation" */ '@/components/ChartLimitation.vue')
const CalendarSchedule = () => import(/* webpackChunkName: "calendar-schedule" */ '@/components/CalendarSchedule.vue')
const TableLimit = () => import(/* webpackChunkName: "table-limit" */ '@/components/TableLimit.vue')
const DateTime = () => import(/* webpackChunkName: "date-time" */ '@/components/DateTime.vue')
const DialogAFL = () => import(/* webpackChunkName: "dialog-afl" */ '@/components/DialogAFL.vue')
const EmptyFlight = () => import(/* webpackChunkName: "empty-flight" */ '@/components/EmptyFlight.vue')

Vue.prototype.$isChrome = function () {
  // const isChrome = /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor)
  // const isChrome = /Google Inc/.test(navigator.vendor)
  const isChrome = true
  return isChrome
}

Vue.component('PullRefresh', PullRefresh)
Vue.component('OfflineWarn', OfflineWarn)
Vue.component('CloudBackground', CloudBackground)
Vue.component('SelectPilot', SelectPilot)
Vue.component('SplashScreen', SplashScreen)
Vue.component('ChromeOnly', ChromeOnly)
Vue.component('FormLeave', FormLeave)
Vue.component('FormLeaveApp', FormLeaveApp)
Vue.component('Flex', Flex)
Vue.component('Loading', Loading)
Vue.component('LittleLoading', LittleLoading)
Vue.component('ChartLimit', ChartLimit)
Vue.component('ChartLimitation', ChartLimitation)
Vue.component('CalendarSchedule', CalendarSchedule)
Vue.component('TableLimit', TableLimit)
Vue.component('DateTime', DateTime)
Vue.component('DialogAFL', DialogAFL)
Vue.component('EmptyFlight', EmptyFlight)

Vue.prototype.$moment = moment

Vue.prototype.$role = function (roles, role) {
  if (role === 'root' || role === 'admin') {
    return true
  } else {
    return roles.find(item => item === role)
  }
}

Vue.prototype.$TodayDate = function () {
  const today = new Date()
  const dd = String(today.getDate()).padStart(2, '0')
  const mm = String(today.getMonth() + 1).padStart(2, '0')
  const yyyy = today.getFullYear()
  const todaydate = yyyy + '-' + mm + '-' + dd
  return todaydate
}

Vue.prototype.$max24 = function (t) {
  const a1 = t.split(/:/)
  const hours = (parseInt(a1[0]) + Math.floor(parseInt(a1[1]) / 60))
  const minutes = parseInt(a1[1]) % 60
  let r = null
  if (hours <= 23 && minutes <= 59) {
    r = {
      nextday: false,
      time: (hours < 10 ? '0' + hours : hours) + ':' + (minutes < 10 ? '0' + minutes : minutes)
    }
  } else {
    const newhours = hours - 24
    r = {
      nextday: true,
      time: (newhours < 10 ? '0' + newhours : newhours) + ':' + (minutes < 10 ? '0' + minutes : minutes)
    }
  }
  return r
}

Vue.prototype.$DateReformat = function (dt) {
  const ndate = new Date(dt)
  const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
    'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
  ]
  const year = ndate.getFullYear().toString().substr(-2)
  const month = monthNames[ndate.getMonth()]
  const day = ndate.getDate()
  return day + ' ' + month + ' ' + year
}

Vue.prototype.$DateReformatBack = function (dt) {
  const ndate = new Date(dt)
  const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
    'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
  ]
  const year = ndate.getFullYear().toString().substr(-2)
  const month = monthNames[ndate.getMonth()]
  const day = ndate.getDate()
  return day + ' ' + month + ' ' + year
}

Vue.prototype.$DateMonthReformat = function (dt) {
  const newFormat = dt.split(' ')
  return newFormat[1] + ' ' + newFormat[0]
}

Vue.prototype.$getThisMonth = function (dtcustom) {
  const formattedDate = function (date) {
    const m = ('0' + (date.getMonth() + 1)).slice(-2)
    const d = ('0' + date.getDate()).slice(-2)
    const y = date.getFullYear()
    return y + '-' + m + '-' + d
  }
  const currDate = dtcustom ? new Date(dtcustom) : new Date()
  const firstDay = new Date(currDate.getFullYear(), currDate.getMonth(), 1)
  const lastDay = new Date(currDate.getFullYear(), currDate.getMonth() + 1, 0)
  const monthStartDate = formattedDate(firstDay)
  const monthEndDate = formattedDate(lastDay)
  return {
    from: monthStartDate,
    to: monthEndDate
  }
}

Vue.prototype.$getThisYear = function () {
  const curr = new Date()
  const year = curr.getFullYear()

  const firstyear = new Date(year + '-01-01').toUTCString()
  const lastyear = new Date(year + '-12-31').toUTCString()
  return {
    from: firstyear,
    to: lastyear
  }
}

Vue.prototype.$PilotScheduleStatus = function (status, PilotScheduleData) {
  status = parseInt(status)
  if ((status === 0 || status > 0)) {
    return PilotScheduleData[status]
  }
}

Vue.prototype.$TodayDate = function () {
  const today = new Date()
  const dd = String(today.getDate()).padStart(2, '0')
  const mm = String(today.getMonth() + 1).padStart(2, '0')
  const yyyy = today.getFullYear()
  const todaydate = yyyy + '-' + mm + '-' + dd
  return todaydate
}

Vue.prototype.$DateRangeDays = function (fromd, tod) {
  const from = new Date(fromd)
  const to = new Date(tod)
  return parseInt(((from - to) / 86400000))
}

Vue.prototype.$DateAddDays = function (dt, days) {
  const date = new Date(dt)
  date.setDate(date.getDate() + days)
  const dd = String(date.getDate()).padStart(2, '0')
  const mm = String(date.getMonth() + 1).padStart(2, '0')
  const yyyy = date.getFullYear()
  const ddt = yyyy + '-' + mm + '-' + dd
  return ddt
}

Vue.prototype.$DateDiffDays = function (dt, days) {
  const date = new Date(dt)
  date.setDate(date.getDate() - days)
  const dd = String(date.getDate()).padStart(2, '0')
  const mm = String(date.getMonth() + 1).padStart(2, '0')
  const yyyy = date.getFullYear()
  const ddt = yyyy + '-' + mm + '-' + dd
  return ddt
}

Vue.prototype.$NumberOnly = function (e) {
  const key = e.keyCode ? e.keyCode : e.which
  if (isNaN(String.fromCharCode(key)) && key !== 8 && key !== 46 && key !== 37 && key !== 39) {
    e.preventDefault()
    return false
  }
}

Vue.prototype.$JsonToStr = function (json) {
  if (json) {
    try {
      json = JSON.stringify(json)
    } catch {
      json = null
    }
    return json
  }
  return null
}

Vue.prototype.$StrToJson = function (str) {
  if (str) {
    try {
      str = JSON.parse(str)
    } catch {
      str = null
    }
    return str
  }
  return null
}

Vue.prototype.$imgtoBase64 = function (event, callback) {
  /* eslint-disable */
  function getType(ext) {
    if (ext === 'mov' || ext === 'mp4' || ext === 'avi' || ext === 'flv') {
      return 'video'
    } else if (ext === 'doc' || ext === 'docx' || ext === 'ppt' || ext === 'pptx' || ext === 'xls' || ext === 'xlsx' || ext === 'csv' || ext === 'txt' || ext === 'pdf' || ext === 'psd') {
      return 'doc'
    } else if (ext === 'jpg' || ext === 'jpeg' || ext === 'gif' || ext === 'png' || ext === 'svg' || ext === 'heic') {
      return 'photo'
    } else if (ext === 'mp3' || ext === 'wav') {
      return 'audio'
    } else {
      return 'unknown'
    }
  }
  let r = {
    status: false,
    ext: '',
    type: '',
    data: null
  }
  let f = event.target.files || event.dataTransfer.files

  const reader = new FileReader()
  if (f[0]) {
    const fname = event.target.files[0].name
    const lastDot = fname.lastIndexOf('.')
    r.ext = fname.substring(lastDot + 1)
    r.type = getType(r.ext)

    const fSize = r.ext === 'mov' || r.ext === 'mp4' || r.ext === 'avi' || r.ext === 'flv' ? 20000000 : 5000000 // 20MB : 5MB

    if (f[0].size <= fSize) {
      reader.readAsDataURL(f[0])
      reader.onload = function (ev) {
        r.status = true
        r.data = ev.target.result
        callback(r)
      }
      reader.onerror = function (error) {
        r.status = false
        r.data = error
        callback(r)
      }
    } else {
      r.status = false
      r.data = 'file_size'
      callback(r)
    }
  } else {
    r.status = false
    r.data = 'canceled'
    callback(r)
  }
}

Vue.prototype.$addMinutes = function (date, minutes) {
  return new Date(date.getTime() + (minutes * 60000))
}

Vue.prototype.$localDT = function (utcDate, type) {
  const localDate = utcDate ? new Date(utcDate) : new Date()
  const dayNames = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
  const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
  // const year = localDate.getFullYear().toString().substr(-2)
  const year = localDate.getFullYear().toString()
  const month = monthNames[localDate.getMonth()]
  const day = localDate.getDate()
  const dayName = dayNames[localDate.getDay()]

  /* eslint-disable-next-line */
  const seconds = localDate.getSeconds()
  const minutes = localDate.getMinutes()
  const hour = localDate.getHours()

  const MonthNumber = ((localDate.getMonth() + 1) < 10 ? '0' + (localDate.getMonth() + 1) : (localDate.getMonth() + 1))

  if (type === 'daydate') {
    return dayName + ', ' + (day < 10 ? '0' + day : day) + ' ' + month + ' ' + year
  } else if (type === 'date') {
    return dayName + ' ' + month + ', ' + year
  } else if (type === 'daydate') {
    return dayName + ', ' + day + ' ' + month + ' ' + year
  } else if (type === 'datedefault') {
    return year + '-' + MonthNumber + '-' + (day < 10 ? '0' + day : day)
  } else if (type === 'datetimedefault') {
    return year + '-' + MonthNumber + '-' + (day < 10 ? '0' + day : day) + ' ' + (hour < 10 ? '0' + hour : hour) + ':' + (minutes < 10 ? '0' + minutes : minutes)
  } else if (type === 'time') {
    return (hour < 10 ? '0' + hour : hour) + ':' + (minutes < 10 ? '0' + minutes : minutes)
  } else if (type === 'datetime') {
    return dayName + ' ' + month + ', ' + year + ' ' + (hour < 10 ? '0' + hour : hour) + ':' + (minutes < 10 ? '0' + minutes : minutes)
  } else {
    return localDate
  }
}

Vue.prototype.$getUTC = function (t) {
  const localDate = t ? (new Date(t)) : new Date()
  const utcYear = localDate.getUTCFullYear()
  const utcMonth = localDate.getUTCMonth() + 1
  const utcDate = localDate.getUTCDate()
  const utcHours = localDate.getUTCHours()
  const utcMinutes = localDate.getUTCMinutes()
  const date = utcYear + '-' + (utcMonth < 10 ? '0' + utcMonth : utcMonth) + '-' + (utcDate < 10 ? '0' + utcDate : utcDate)
  const time = (utcHours < 10 ? '0' + utcHours : utcHours) + ':' + (utcMinutes < 10 ? '0' + utcMinutes : utcMinutes)
  return date + 'T' + time + ':00'
}

Vue.prototype.$getTimezone = function () {
  const t = parseInt(-(new Date().getTimezoneOffset() / 60))
  return t < 0 ? 'GMT' + t : 'GMT+' + t
}

Vue.prototype.$parseRoutes = function (routes) {
  try {
    return JSON.parse(routes)
  } catch {
    return null
  }
}

Vue.prototype.$getDistance = function (p1, p2) {
  const rad = function (x) {
    return x * Math.PI / 180
  }

  const R = 6378137 // Earth’s mean radius in meter
  const dLat = rad(parseFloat(p2.lat) - parseFloat(p1.lat))
  const dLong = rad(parseFloat(p2.lng) - parseFloat(p1.lng))
  const a = Math.sin(dLat / 2) * Math.sin(dLat / 2) + Math.cos(rad(parseFloat(p1.lat))) * Math.cos(rad(parseFloat(p2.lat))) * Math.sin(dLong / 2) * Math.sin(dLong / 2)
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))
  const d = R * c
  const result = (d / 1000) / 1.852
  return result ? result.toFixed(2) : '~'
}

Vue.prototype.$dtTimestamp = function (dateTimeString) {
  return Math.floor(new Date(dateTimeString) / 1000)
}

Vue.prototype.$sortArray = function (arr, key) {
  if (typeof arr === 'object') {
    return arr.sort((a, b) => (a[key] > b[key]) ? 1 : ((b[key] > a[key]) ? -1 : 0))
  } else {
    return arr
  }
}

Vue.prototype.$ArrayToStr = function (json) {
  if (json) {
    try {
      json = JSON.stringify(json)
    } catch {
      json = json
    }
    return json
  }
  return ''
}

Vue.prototype.$ReformatToArray = function (str) {
  if (str) {
    return this.$StrToArray(str)
  }
  return []
}

Vue.prototype.$StrToArray = function (str) {
  if (str) {
    if (str.substr(0, 1) === '[') {
      try {
        str = JSON.parse(str)
      } catch {
        str = [str]
      }
    } else {
      str = [str]
    }
    return str
  }
  return []
}

Vue.prototype.$SplitSymbol = (source, symbol) => {
  let arr
  if (symbol) {
    arr = source.split(symbol)
  } else {
    arr = source.split(' ')
  }
  return arr
}

Vue.prototype.$formatPhone = (number) => {
  number = number.replace(/[-\s]/g, "")

  if (number.charAt(0) === "+") {
    number = number.replace(/^\+62/, "62");
  } else if (number.charAt(0) === "0") {
    number = "62" + number.slice(1)
  }
  return number
}

Vue.prototype.$timeToDecimal = function (t, p) {
  const arr = t.split(':')
  const dec = parseInt((arr[1] / 6) * 10, 10)
  return parseFloat(parseInt(arr[0], 10) + '.' + (dec < 10 ? '0' : '') + dec).toFixed(parseInt(p) ? parseInt(p) : 1)
}

Vue.prototype.$convertFullName = function (obj, sub) {
  if (sub) {
    const iata_code = sub === 'dep' ? obj.dep_iata : obj.arr_iata
    const icao_code = sub === 'dep' ? obj.dep_icao : obj.arr_icao
    const city_name = sub === 'dep' ? obj.dep_cityname : obj.arr_cityname
    const name = sub === 'dep' ? obj.dep_name : obj.arr_name
    const IataIcao = (icao_code || iata_code) ? (icao_code || iata_code) : ''
    const r = ((IataIcao.match(/x-/gi) || IataIcao.match(/XX/gi)) ? null : IataIcao + ' - ' + ((city_name || name) ? (city_name || name) : '')) || ((city_name || name) ? (city_name || name) : 'No Name')
    return r
  } else {
    // const r = (obj.iata_code || '-') + '|' + (obj.icao_code || '-') + ' ' + (obj.name ? obj.name : 'no name')
    const IataIcao = (obj.icao_code || obj.iata_code) ? (obj.icao_code || obj.iata_code) : ''
    const r = ((IataIcao.match(/x-/gi) || IataIcao.match(/XX/gi)) ? null : IataIcao + ' - ' + ((obj.city_name || obj.name) ? (obj.city_name || obj.name) : '')) || ((obj.city_name || obj.name) ? (obj.city_name || obj.name) : 'No Name')
    return r
  }
}

Vue.prototype.$convertShortName = function (obj, sub) {
  if (sub) {
    const iata_code = sub === 'dep' ? obj.dep_iata : obj.arr_iata
    const icao_code = sub === 'dep' ? obj.dep_icao : obj.arr_icao
    const city_name = sub === 'dep' ? obj.dep_cityname : obj.arr_cityname
    const name = sub === 'dep' ? obj.dep_name : obj.arr_name
    const IataIcao = (icao_code || iata_code) ? (icao_code || iata_code) : ''
    const r = ((IataIcao.match(/x-/gi) || IataIcao.match(/XX/gi)) ? null : IataIcao) || ((city_name || name) ? (city_name || name) : 'No Name')
    return r
  } else {
    // const r = (obj.iata_code || '-') + '|' + (obj.icao_code || '-') + ' ' + (obj.name ? obj.name : 'no name')
    const IataIcao = (obj.icao_code || obj.iata_code) ? (obj.icao_code || obj.iata_code) : ''
    const r = ((IataIcao.match(/x-/gi) || IataIcao.match(/XX/gi)) ? null : IataIcao) || ((obj.city_name || obj.name) ? (obj.city_name || obj.name) : 'No Name')
    return r
  }
}

Vue.prototype.$openLink = function (link, blank, download) {
  const a = document.createElement('a')
  if (blank) {
    a.setAttribute('target', '_blank')
  }
  if (download) {
    a.setAttribute('download', 'download')
  }
  a.href = link
  document.body.appendChild(a)
  a.click()
  setTimeout(() => {
    a.parentNode.removeChild(a)
  }, 100)
}

Vue.prototype.$dayStatus = function (res) {
  const s = res.count_schedules || 0
  const l = res.count_logbooks || 0
  if (parseInt(s) > 0 && parseInt(s) === parseInt(l)) {
    return 'green'
  } else if (parseInt(s) > 0 && parseInt(s) > parseInt(l)) {
    return 'red'
  }
  return ''
}

Vue.prototype.$DayNextDays = function (d1, dNow) {
  if (new Date(d1) >= new Date(dNow)) {
    return true
  } else {
    return false
  }
}

Vue.prototype.$getSumEstimate = function (init, index, data) {
  const TodayIndex = Math.round(data.length / 2)
  // let ftInit = init
  let ftInit = 0
  data.map((r, i) => {
    if (i >= TodayIndex && i <= index) {
      ftInit += r
    }
  })
  return parseFloat(ftInit) ? parseFloat(parseFloat(ftInit).toFixed(1)) : 0
}

Vue.prototype.$logbookPassed = function (attr) {
  if (parseInt(attr.copilot)) {
    return parseInt(attr.flight_logbook) === 2
  } else if (attr.pilot) {
    return parseInt(attr.flight_logbook) === 1
  }
  return false
}

Vue.prototype.$JsonToStr = function (json) {
  if (json) {
    try {
      json = JSON.stringify(json)
    } catch {
      json = json || ''
    }
  }
  return json
}

Vue.prototype.$StrToJson = function (str) {
  if (str) {
    try {
      str = JSON.parse(str)
    } catch {
      str = []
    }
    return str
  }
  return []
}

Vue.prototype.$ArrayToStr = function (json) {
  if (json) {
    try {
      json = JSON.stringify(json)
    } catch {
      json = json || '[]'
    }
    return json
  }
  return '[]'
}

Vue.prototype.$StrToArray = function (str) {
  if (str) {
    try {
      str = JSON.parse(str)
    } catch {
      str = []
    }
  }
  return str
}

Vue.prototype.$GetTimeUTC = function (withSecond) {
  const d = new Date()
  const utcDate = d.getUTCDate()
  const utcMonth = d.getUTCMonth()
  const utcYear = d.getUTCFullYear()
  const utcHours = d.getUTCHours()
  const utcMinutes = d.getUTCMinutes()
  const utcSeconds = withSecond ? d.getUTCSeconds() : ''
  const date = utcYear + '-' + (parseInt(utcMonth) < 10 ? ('0' + utcMonth) : utcMinutes) + '-' + (parseInt(utcDate) < 10 ? ('0' + utcDate) : utcDate)
  const time = (parseInt(utcHours) < 10 ? ('0' + utcHours) : utcHours) + ':' + (parseInt(utcMinutes) < 10 ? ('0' + utcMinutes) : utcMinutes) + (withSecond ? (':' + (parseInt(utcSeconds) < 10 ? ('0' + utcSeconds) : utcSeconds)) : '')
  return {
    date,
    time
  }
}
Vue.prototype.$FlightType = function (ftype, fTypeList) {
  const type = fTypeList.find(r => r.value = ftype)
  if (type) {
    return type
  }
  return {
    name: 'Undefined',
    value: t || 'undefined',
    color: '#aaaaaa'
  }
}

Vue.prototype.$dTime = function (t1, t2, p) {
  const a = t1.split(/:/)
  const b = t2.split(/:/)
  const date1 = new Date(2000, 0, 1, parseInt(a[0]), parseInt(a[1]))
  const date2 = new Date(2000, 0, 1, parseInt(b[0]), parseInt(b[1]))
  if (date2 < date1) {
    date2.setDate(date2.getDate() + 1)
  }
  const diff = (date2 - date1) / 1000
  return parseFloat((diff / 60 / 60).toFixed(parseInt(p) ? parseInt(p) : 1))
}
Vue.prototype.$ReformatTime = function (t) {
  if (t) {
    if (t.length === 4 && t[2] !== ':') {
      return t.substr(0, 2) + ':' + t.substr(2, 2)
    }
  }
  return t
}
Vue.prototype.$CompareTime = function (t1, t2) {
  if (t1 && t2) {
    t1 = t1 ? (t1.length === 5 ? (t1 + ':00') : '00:00:00') : '00:00:00'
    t2 = t2 ? (t2.length === 5 ? (t2 + ':00') : '00:00:00') : '00:00:00'
    return Date.parse('01/01/2011 ' + t1) > Date.parse('01/01/2011 ' + t2)
  }
  return null
}
Vue.prototype.$IsValidTime = function (t) {
  if (t) {
    if (t.length === 5) {
      if (t[2] === ':') {
        const h = parseInt(t.substr(0, 2))
        const m = parseInt(t.substr(3, 2))
        if ((h >= 0 && h <= 23) && (m >= 0 && m <= 59)) {
          return true
        }
      }
    }
  }
  return false
}

Vue.prototype.$TimeDutyTotal = function (dutyDate, t1, t2) {
  function diffHours (dt1, dt2) {
    let diff = (dt2.getTime() - dt1.getTime()) / 1000
    diff /= (60 * 60)
    return Math.abs(diff).toFixed(1)
  }

  const a = t1.split(/:/)
  const b = t2.split(/:/)
  const dutyStart = new Date(dutyDate + 'T' + t1 + ':00')
  const dutyEnd = new Date(dutyDate + 'T' + t2 + ':00')
  if (parseInt(a[0]) > parseInt(b[0])) {
    dutyStart.setDate(dutyStart.getDate() - 1)
    return diffHours(dutyStart, dutyEnd)
  }
  return diffHours(dutyStart, dutyEnd)
}

Vue.prototype.$diffHours = function (dtFrom, dtTo) {
  const dt1 = new Date(dtFrom)
  const dt2 = new Date(dtTo)
  let diff = (dt2.getTime() - dt1.getTime()) / 1000
  diff /= (60 * 60)
  return Math.abs(diff).toFixed(1)
}

Vue.prototype.$TimeDuty = function (type, t1, t2) {
  const a = t1.split(/:/)
  const b = t2.split(/:/)
  let hours = 0
  let minutes = 0
  if (type === 'diff') {
    let diffMinutes = parseInt(a[1]) - parseInt(b[1])
    let diffHours = parseInt(a[0])
    if (diffMinutes < 0) {
      diffMinutes = 60 + diffMinutes
      if (diffHours > 0) {
        diffHours = diffHours - 1
      } else {
        diffHours = 23
      }
      hours = (diffHours < 10 ? ('0' + diffHours) : ('' + diffHours))
      minutes = (diffMinutes < 10 ? ('0' + diffMinutes) : ('' + diffMinutes))
      return {
        status: true,
        hours,
        minutes,
        data: hours + ':' + minutes
      }
    }
    hours = (diffHours < 10 ? ('0' + diffHours) : ('' + diffHours))
    minutes = (diffMinutes < 10 ? ('0' + diffMinutes) : ('' + diffMinutes))
    return {
      status: true,
      hours,
      minutes,
      data: hours + ':' + minutes
    }
  } else if (type === 'add') {
    let addMinutes = parseInt(a[1]) + parseInt(b[1])
    let addHours = parseInt(a[0])
    if (addMinutes > 59) {
      addMinutes = addMinutes - 60
      if (addHours < 23) {
        addHours = addHours + 1
      } else {
        addHours = 0
      }
      hours = (addHours < 10 ? ('0' + addHours) : ('' + addHours))
      minutes = (addMinutes < 10 ? ('0' + addMinutes) : ('' + addMinutes))
      return {
        status: true,
        hours,
        minutes,
        data: hours + ':' + minutes
      }
    }
    hours = (addHours < 10 ? ('0' + addHours) : ('' + addHours))
    minutes = (addMinutes < 10 ? ('0' + addMinutes) : ('' + addMinutes))
    return {
      status: true,
      hours,
      minutes,
      data: hours + ':' + minutes
    }
  }
  return {
    status: false
  }
}

Vue.prototype.$formatNumber = (number, type) => {
  let FormatNum = new Intl.NumberFormat('en-US', {
    style: type || type === 'currency' ? 'currency' : 'decimal',
    currency: 'USD',
  })

  return FormatNum.format(number);
};

Vue.prototype.$SweetAlert = ({
  title = title || '',
  caption = caption || '',
  icon = icon || false,
  confirmText = confirmText || 'Confirm',
  cancelText = cancelText || 'Cancel',
  additionalBtn = additionalBtn || {},
  onConfirm = () => {},
  onCancel = () => {}
}) => {
  Swal.fire({
    title: title,
    text: caption,
    icon: icon,
    showCancelButton: true,
    showDenyButton: Object.keys(additionalBtn).length ? true : false,
    confirmButtonText: confirmText,
    cancelButtonText: cancelText,
    denyButtonText: Object.keys(additionalBtn).length ? additionalBtn.text : 'Deny',
    confirmButtonColor: '#E33F5A',
    cancelButtonColor: '#C1C1C1',
    denyButtonColor: Object.keys(additionalBtn).length && additionalBtn.color ? additionalBtn.color : '#C1C1C1',
    showClass: {
      popup: 'animate__animated animate__slideInUp animate__faster',
      icon: 'animate__animated animate__heartBeat delay-1s'
    },
    hideClass: {
      popup: 'animate__animated animate__slideOutDown animate__faster',
    }
  }).then((result) => {
    if (result.isConfirmed) {
      onConfirm()
    } else if (result.isDenied) {
      additionalBtn.action()
      Swal.close()
    } else {
      onCancel()
      Swal.close()
    }
  })
}

// Toast Notification
Vue.prototype.$toastNotif = function ({
  message = '',
  type = 'info',
  timer = 2500
}) {
  Swal.fire({
    position: 'top',
    text: message,
    icon: type,
    toast: true,
    timer: timer,
    showConfirmButton: false,
    showClass: {
      popup: 'animate__animated animate__flipInX animate__faster',
      icon: 'animate__animated animate__jello delay-1s'
    },
    hideClass: {
      popup: 'animate__animated animate__slideOutUp animate__faster',
    }
  })
}

Vue.prototype.$Console = (data) => {
  console.log(data)
}