import request from '../../_api/request'

export default {
  async FORM_LEAVE_GET (context, query) {
    return request.get('pilot/form-leave' + (query || '')).then((res) => {
      if (res.status) {
        context.commit('SET_FORM_LEAVE', { data: res.data.data.data, total: parseInt(res.data.data.total) })
      } else {
        context.commit('SET_FORM_LEAVE', { data: [], total: 0 })
      }
      return res
    }).catch(e => null)
  },
  async FORM_LEAVE_GET_ONE (context, id) {
    return request.get('pilot/form-leave/' + id).then(res => res).catch(e => null)
  },
  async FORM_LEAVE_PROCESS (context, params) {
    return request.post('pilot/form-leave', params).then((res) => res).catch(e => null)
  }
}
